.reward{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.2);
    /* -webkit-box-shadow: 0px 10px 20px -8px rgba(0,0,0,0.2); */
    width: 250px;
    height: 170px;
    text-decoration: none;
    cursor: pointer;
    color:white;
    padding: 15px 20px;
    margin: 20px 10px;
}

.reward-bottom{
    display: flex;
    justify-content: right;
}
  
.reward-title{
    margin: 0;
    font-size: 20px;
    font-weight: 700;
}

.reward-points{
    width: auto;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.reward-star{
    height: 15px;
    width: auto;
    margin: 0 4px 0 2px;
}

.reward-logo{
    height: 25px;
}

@media (max-width: 500px ){
    .reward{
        margin: 10px 0;
    }
}