.blue-border:focus{
    border: solid 1px #4d90fe;
}

.apply-page{
    height: 100vh;
    overflow: scroll;
}

.apply-page-top{
    height: 12em;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
    z-index: 1;
    position: relative;
}

.apply-page-top-text{
    height: calc(100% - 50px)
}

.apply-page-top-buttons{
    height: 50px;
    display: flex;
    justify-content: center;
}

.apply-page-top-button{
    background-color: initial;
    border-radius: 0;
    color: #9085a5;
    font-size: .85em;
    margin: 0px 10px;
    /* transition: color .5s; */
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s;
}

.apply-page-top-button-selected {
    box-shadow: inset 0 -2px 0 0 #8075ff;
    color: #8075ff;
}

.apply-page-main{
    width: 100%;
    background-color: #f6f5f8;
    min-height: calc(100% - 12em);
    display: flex;
    justify-content: center;
}

.apply-page-main-inner{
    width: 750px;
    padding: 2em;
}

.apply-page-application-note{
    font-size: 0.8em;
    color: #8B88A7;
}

.apply-page-section-title{
    font-size: 1.2em;
    border-bottom: solid 1px #dfdfe3;
    padding: 1em 0 0.5em 0;
    margin-bottom: 1em;
}

.application-question{
    margin-bottom: 0.8em;
    position: relative;
}

.apply-page-application-name{
    display: flex;
}

.application-question-left{
    width: 50%;
    padding-right: 1em;
}

.application-question-right{
    width: 50%;
    padding-left: 1em;
}

.application-question-title{
    padding: 0.7em 0;
    font-size: 0.8em;
    font-weight: 600;
}

.application-question-optional{
    font-weight: 400;
    color: #8B88A7;
}

.application-question-phone-container {
    /* width: 100%; */
    font-size: 1em !important;
}

.application-question-phone-input:focus {
    border: solid 1px #4d90fe !important;
    outline: none !important;
}

.application-question-phone-input {
    width: 100% !important;
    padding: 7px 10px 7px 48px !important;
    border: 1px solid !important;
    border-color: rgb(213, 213, 213) !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5px !important;
    font-family: Helvetica;
    font-size: 0.8em !important;
    line-height: 1.5rem !important;
    height: auto !important;
}

.application-question-phone-dropdown{
    font-size: 0.8em;
    border-radius: 5px !important;
    margin: 6px 0 0 -1px !important;
    max-height: 150px !important;
}

.application-question-phone-dropdown li:hover{
    background-color: #f2f2f2;
}

.application-question-phone-button{
    border-right: 1px solid !important;
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
    margin: 1px 0 1px 1px;
    border-color: rgb(213, 213, 213) !important;
    background: none !important;
    /* border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; */
}

.application-question-phone-button .selected-flag{
    background: none !important;
}

/* .application-question-phone-button:hover{
    background: transparent !important;
}

.application-question-phone-button:focus{
    background: none !important;
} */

.apply-page-application-experience{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
}

.apply-page-add-experience {
    align-items: center;
    border: 1px solid #8075ff;
    border-radius: 5px;
    color: #8075ff;
    cursor: pointer;
    display: flex;
    font-size: 0.8em;
    height: 34px;
    padding: 0 10px;
    transition: .5s;
}

.add-experience-plus{
    height: 14px;
    padding-right: 6px;
}

.apply-page-add-experience:hover {
    color: white;
    background-color: #8075FF;
}

.apply-page-application-experience-wrapper{
    display: flex;
    flex-direction: column;
}

.apply-page-application-experience-box{
    display: flex;
    align-items: top;
    width: 100%;
    border-radius: .3em;
    padding: 2em;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    background-color: white;
    flex-direction: column;
    margin-top: 1em;
}

.application-experience-title-company-wrapper{
    width: 100%;
    display: flex;
}

.react-datepicker__month-text--keyboard-selected{
    background-color: #dfdfe3;
}

.react-datepicker__month-text--keyboard-selected:hover{
    background-color: #dfdfe3;
}

.react-datepicker__month-text:hover{
    background-color: #f2f2f2;
}

.application-experience-buttons{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 0.7em;
}

.application-experience-title{
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.2em;
}

.application-experience-company{
    font-size: 0.8em;
    /* font-weight: 500; */
    margin-bottom: 0.1em;
}

.application-experience-date{
    font-size: 0.8em;
    color: #8B88A7;
    margin-bottom: 0.1em;
}

.application-experience-desc{
    font-size: 0.8em;
    margin-top: 0.7em;    
    white-space: pre-line;
}

.apply-page-application-experience-box-top{
    width: 100%;
    /* position: relative; */
    display: flex;
    justify-content: space-between;
}

.apply-page-application-experience-box-top-left{
    width: calc(100% - 2em);
}

.apply-page-application-experience-dropdown{
    font-size: 0.9em;
}

.apply-page .react-datepicker__input-container input:focus{
    border: solid 1px #4d90fe;
    outline: none;
}

.experience-dropdown-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.7em 1em;  
    font-size: 0.9em;  
    cursor: pointer;
}

.experience-dropdown-item:hover{
    background-color: #F6F5FF;
}

.submit-application-button{
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    /* width: 100px; */
    cursor: pointer; 
    transition: opacity .4s;
    background-color: #aca4ff;
    color: white;
    flex-shrink: 0;
    border-radius: 10px;
}

.submit-application-button:hover{
    opacity: 70%;
}

.submit-application-button-loading{
    opacity: 50%;
    cursor: default;
}

.submit-application-button-loading:hover{
    opacity: 50%;
}

.submit-application-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.drag-style{
    position: relative;
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 1.5px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #DFDFE3;
    background-color: #ffffff;
    transition: 0.5s;
    margin-bottom: 0.8em;
}

.drag-upload-button{
    cursor: pointer;
    color: #8075FF;
    font-weight: 500;
}

.drag-upload-button:hover{
    text-decoration: underline;
}

#application-cloud-icon{
    height: 4em;
}

#application-drag-inner-container {
    padding: 1em;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    border-radius: 1rem;
    cursor: pointer;
}

.application-drag-active {
    background-color: #F6F5FF;
}

.drag-file-selected-wrapper{
    display: flex;
    align-items: center;
    /* padding: 1em; */
    background-color: #F6F5FF;
    border-radius: 0.5em;
    cursor: auto;
    margin: 0.5em;
    height: 3em;
    padding: 0.5em 1em;
    color: #8075FF;
}

.drag-file-paperclip{
    height: 0.8em;
    color: #8075FF;
}

.drag-file-x{
    height: 1em;
    color: #8075FF;
    margin-left: 0.5em;
    cursor: pointer;
}

.application-drag-filename-text {
    font-size: 0.8em;
    color: #8075FF;
    text-align: center;
    padding: 0 5px;
}

.apply-page-desc{
    margin-bottom: 0.8em;
}

.apply-page-desc-title{
    padding: 0.7em 0;
    font-size: 0.8em;
    font-weight: 600;
}

.apply-page-desc-desc{
    font-size: 0.8em;
}

.application-question-checkbox{
    font-size: 0.8em;
    font-weight: 500;
    margin: 0.5em 0;
}