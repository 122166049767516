.checkbox-wrapper{
    display: flex;
    align-items: center;
}

.checkbox-checked{
    height: 22px;
    width: 22px;
    flex-shrink: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    /* margin-right: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4d90fe;
    border: 2px solid #4d90fe;
    animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
    transition: background-color 250ms cubic-bezier(.4,.0,.23,1);
}

.checkbox-unchecked{
    height: 22px;
    width: 22px;
    flex-shrink: 0;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    /* margin-right: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(213, 213, 213);
    background-color: white;  
    animation: shrink-bounce-too 200ms cubic-bezier(.4,.0,.23,1);
    transition: background-color 250ms cubic-bezier(.4,.0,.23,1);  
}

.checkbox-box-disabled{
    cursor: auto;
    background-color: transparent;
}

.checkbox-checkmark-checked{
    max-width: 18px;
    max-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: 0.5s ease-in-out; */
} 

.checkbox-checkmark-hidden{
    max-width: 18px;
    max-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
} 

.checkbox-text{
    cursor: pointer;
    padding-left: 10px;
}

.checkbox-text-disabled{
    color: rgb(213, 213, 213);
    cursor: auto;
}


@keyframes shrink-bounce{
    0%{
        transform: scale(1);
    }
    33%{    
        transform: scale(.85);
    }
    100%{
        transform: scale(1);    
    }
}

@keyframes shrink-bounce-too{
    0%{
        transform: scale(1);
    }
    33%{    
        transform: scale(.85);
    }
    100%{
        transform: scale(1);    
    }
}