.error-modal-inner-wrapper{
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    color: #5A4D69;
    background-color: white;
    border-radius: 10px;
    max-height: 100%;
    overflow: scroll;
}

.success-confirm-modal-inner-wrapper{
    color: #5A4D69;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    background-color: white;
    border-radius: 10px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.input-modal-inner-wrapper{
    padding: 30px 0;
    width: 500px;
    color: #5A4D69;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.wide-modal{
    width: 500px;
}

.input-modal-main-area{
    width: 100%;
    max-height: 300px;
    /* overflow-y: scroll; */
    overflow: scroll;
    margin-bottom: 20px;
    padding: 0 30px;
}

.input-modal-section{
    width: 100%;
    margin-bottom: 20px;
}


.file-select-modal-main-area{
    width: 100%;
    overflow: scroll;
    margin-bottom: 20px;
    padding: 0 30px;
}

.error-modal-image{
    height: 180px;
    margin: 5px 0 15px 0;
    flex-shrink: 0;
}

.error-modal-title{
    font-weight: 600;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 15px;
}

.input-modal-title{
    font-weight: 600;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: left;   
    width: 100%; 
    padding: 0 30px;
}

.input-modal-desc{
    margin-top: 0.5em;
    font-weight: 400;
    font-size: 0.7em;
    text-align: left;   
    width: 100%; 
    color: #8B88A7;
}

.error-modal-text{
    font-size: 0.9em;
    margin-bottom: 30px;
    text-align: center;
}

.input-modal-text{
    font-size: 0.9em;
    margin-bottom: 30px;
    text-align: left;
    width: 100%;
}

.input-modal-label{
    font-size: 0.8em;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
}

.input-modal-spacing{
    width: 100%;
    height: 20px;
}

.error-modal-button{
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 40px;
    width: 220px;
    cursor: pointer; 
    transition: opacity .4s;
    background-color: #A19DC6;
    color: white;
    flex-shrink: 0;
}

.error-modal-button:hover{
    opacity: 70%;
}

.success-modal-button{
    background-color: #aca4ff;
}

.modal-button-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.input-modal-button-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 30px;
}

.input-modal-button-loading{
    opacity: 70%;
}

.input-modal-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.input-modal-button{
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100px;
    cursor: pointer; 
    transition: opacity .4s;
    background-color: #aca4ff;
    color: white;
    flex-shrink: 0;
    border-radius: 10px;
}

.input-modal-button:hover{
    opacity: 70%;
}

.input-modal-button:disabled{
    opacity: 50%;
    cursor: not-allowed;
}

.success-confirm-modal-inner-wrapper .cancel{
    background-color: transparent;
    height: auto;
    width: auto;
    padding-top: 1em;
    text-decoration: underline;
    color: #8c8c8c;
    font-size: 0.8em;    
}

.success-confirm-modal-inner-wrapper .deactivated{
    cursor: not-allowed;
}

.input-modal-inner-wrapper .cancel{
    background-color: transparent;
    height: auto;
    width: auto;
    padding-top: 1em;
    text-decoration: underline;
    color: #8c8c8c;
    font-size: 0.8em;    
}

.input-modal-inner-wrapper .deactivated{
    cursor: not-allowed;
}

.input-modal-cancel{
    background-color: transparent;
    height: auto;
    width: 60px;
    padding-top: 1em;
    text-decoration: underline;
    color: #8c8c8c;
    font-size: 0.8em;    
    margin-right: 1em;   
}

.input-modal-input{
    display: block;
    /* margin-bottom: 20px; */
    width: 100%;
    padding: 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;
    line-height: 1.5rem;
    resize: none;
}

.input-modal-input-last{
    display: block;
    /* margin-bottom: 40px; */
    width: 100%;
    padding: 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;
    line-height: 1.5rem;
    resize: none;
}

.input-modal-input:focus{
    border: solid 1px #8075FF;
    outline: none;
}
  
.input-modal-input-last:focus{
    border: solid 1px #8075FF;
    outline: none;
}

.input-modal-input-box-error{
    border: #ec6f61 solid 1px !important;
}

.input-modal-error{
    color: #ec6f61;
    font-size: 0.7em;
    padding-top: 0;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
}

.input-modal-error-expanded{
    max-height: 50px;
    padding-top: 0.5em;
}

/* .date-picker button{
    width: auto;
    background-color: auto;
} */

.react-datepicker__input-container input{
    display: block;
    width: 100%;
    padding: 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;   
    line-height: 1.5rem; 
}

.react-datepicker__input-container input:focus{
    border: solid 1px #8075FF;
    outline: none;
}

.react-datepicker__input-container input:disabled{
    background-color: #f2f2f2;
    color: #8c8c8c;
}

.react-datepicker-wrapper{
    width: 100%;
}

/* .react-datepicker__day {
    width: 1.5rem !important;
    line-height: 1.5rem !important;
} */

/* .css-t3ipsp-control{
    display: block;
    width: 100%;
    padding: 0 0 0 10px;
    border: 2px solid rgb(213, 213, 213) !important;
    background-color: rgb(255, 255, 255);
    border-radius: 10px !important;
    font-family: Helvetica;
    font-size: 0.8em;        
}

.css-13cymwt-control{
    display: block;
    width: 100%;
    padding: 0 0 0 10px;
    border: 2px solid rgb(213, 213, 213) !important;
    background-color: rgb(255, 255, 255);
    border-radius: 10px !important;
    font-family: Helvetica;
    font-size: 0.8em;        
}

.css-1n6sfyn-MenuList{
    font-size: 0.8em;   
} */

.file-select-modal-main{
    width: 100%;
    margin-bottom: 10px;
}

.file-select-modal-main .file{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    flex-shrink: 0;
}

.file-select-modal-main .skeleton-file{
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 50px;
    width: 100%;
    flex-shrink: 0;
}

.file-select-modal-main .file:hover{
    background-color: #F6F5FF;
}

.file-select-modal-main .template-title{
    width: calc(100% - 100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
}

.file-select-modal-main .template-update-time{
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-select-modal-main .selected-file{
    background-color: #F6F5FF;
    /* border: 1.5px solid #c1bee4; */
    box-shadow:inset 0px 0px 0px 1px #c1bee4;
}

.input-modal-local-upload-box{
    display: flex;
    align-items: center;
    background-color: #EFF0F6;
    width: 100%;
    border-radius: 5px;
    padding: 8px 15px;
}

.upload-box-upload-button{
    width: 120px;
    font-size: 0.8em;
    display: block;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;    
    color: #5A4D69;
    margin-left: 10px;
    transition: 0.5s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-box-upload-button:hover{
    background-color: #F6F5FF;
}

.upload-box-file-name{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
}

.upload-box-no-file-name{
    width: 100%;
    font-size: 0.8em;
    font-weight: 600;
    color: #8c8c8c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.or-line { 
    display: flex; 
    flex-direction: row; 
    width: 100%;
    margin: 20px 0;
    font-size: 0.8em;
    color: #8c8c8c;
} 

.or-line:before{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #DFDFE3;
    margin: auto;
    margin-right: 8px;
}

.or-line:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #DFDFE3;
    margin: auto;
    margin-left: 8px;
}

.select-template-main-scroll{
    height: 200px;
    overflow-y: scroll;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-template-table-keys{
    font-size: 0.8em;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 10px 0;
    border-bottom: 1px solid #DFDFE3;
}

.no-scroll{
    overflow: hidden;
}

.upload-box-upload-button:hover{
    background-color: #F6F5FF;
}

.file-select-modal-main .no-more{
    font-size: 0.8em;
    color: #8c8c8c;
    padding: 10px 0;
    text-align: center;
    height: 35px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.file-select-modal-main .empty{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 0.8em;
}

.file-select-modal-main .empty-icon{
    height: 4em;
    margin-bottom: 5px;
    color: #DFDFE3;
}

#modal-overlay{
    background: rgba(33, 34, 35, 0.7);
    height: 100%;
    position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    width: 100%;
    overflow: hidden;
    /* backdrop-filter: blur(5px); */
}

#modal-wrapper{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    opacity: 1;
    visibility: visible;  
    z-Index: 10;
}

#modal-box{
    /* max-width: 100%; */
    margin: 0 auto;
    /* position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: scroll; */
    z-index: 1;
    max-height: 100%;
}

.jobdesc-modal-text{
    font-size: 0.8em;
    white-space: pre-line;
}

.jobdesc-modal-details{
    font-size: 0.8em;
    color: #8B88A7;
    cursor: pointer;
    transition: color .4s;
    text-decoration: underline;
    font-weight: 400;
}

.jobdesc-modal-details:hover{
    opacity: 70%;
}

.transactions-inner-wrapper .no-more{
    font-size: 0.8em;
    color: #8c8c8c;
    padding: 10px 0;
    text-align: center;
    height: 35px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.transactions-inner-wrapper .empty{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 0.8em;
}

.transactions-inner-wrapper .empty-icon{
    height: 4em;
    margin-bottom: 5px;
    color: #DFDFE3;
}

.input-error-msg{
    font-size: 0.8em;
    color: #ec6f61;
    margin-bottom: 15px;
    padding: 0 30px;
    max-height: 50px;
    transition: 0.5s;
    opacity: 1;
}

.input-error-msg-hidden{
    opacity: 0;
    max-height: 0;
    /* padding: 0;
    margin: 0; */
}

@media (max-width: 500px ){
    .error-modal-inner-wrapper{
        width: 400px;
        padding: 30px;
    }
    .success-confirm-modal-inner-wrapper{
        width: 400px;
        padding: 30px;
    }
    .input-modal-inner-wrapper{
        width: 400px;
        padding: 30px 0px;        
    }
}
  
@media (max-width: 400px ){
    .success-confirm-modal-inner-wrapper{
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .input-modal-inner-wrapper{
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .error-modal-inner-wrapper{
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    #modal-box{
        border-radius: 0;
        width: 100%;
        height: 100%;        
    }

    .input-modal-main-area{
        flex: 1;
        max-height: none;
    }
}



