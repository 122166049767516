.offer{
    display: flex;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
    background-color: white;
    width: 250px;
    height: 300px;
    text-decoration: none;
    cursor: pointer;
    color:#767676;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
}

.offer-image{
    width: 100%;
    height: 50%;
    background-color:rgb(213, 213, 213);
    border-radius: 10px 10px 0px 0px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-logo{
    height: 60px;
}

.offer-logo-modal{
    width: 70px;
}

.offer-texts{
    overflow: hidden;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.offer-title{
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 0.9em;
    color: rgb(90, 77, 105);
    font-weight: 600;
    margin-bottom: 4px;
}

.offer-desc{
    color: #8B88A7;
    font-size: 0.8em;
}

.offer-points{
    width: auto;
}

.offer-bottom{
    display: flex;
}

.offer-bottom-wrapper{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.offer-bottom-title{
    color: rgb(90, 77, 105);
    font-size: 0.8em;
    font-weight: 600;
    margin-bottom: 2px;
}

.offer-bottom-value{
    color: #C5BEFF;
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.offer-bottom-star{
    height: 0.8em;
    width: auto;
    color: #C5BEFF;
}

.offer-modal-inner-wrapper{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.offer-modal-top{
    width: 100%;
    height: 140px;
    border-radius: 10px 10px 0 0 ;
    padding: 30px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    flex-shrink: 0;
}

.offer-modal-top .left{
    display: flex;
    align-items: center;
}

.offer-modal-top .main{
    margin-left: 20px;
    width: 60%;
}

.offer-modal-top .title{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 5px;
}

.offer-modal-top .short-desc{
    font-size: 0.9em;
}

.points-number-wrapper{
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 600;
    text-align: right;
    justify-content: flex-end;
    white-space: nowrap;
}

.offer-modal-star{
    height: 1em;
    width: auto;
    padding: 0 0.2em;
}

.offer-modal-top .right{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.offer-modal-top .points-text{
    font-size: 0.9em;
}

.offer-modal-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 30px;
    flex-shrink: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
}

.offer-modal-bottom .button{
    font-size: 1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 42px;
    width: max(min(300px, 60%), 200px);
    cursor: pointer; 
    transition: opacity .4s;
    margin: 20px;
    flex-shrink: 0;
}

.offer-modal-bottom .button:hover{
    opacity: 70%;
}

.offer-modal-star-button{
    height: 16px;
    width: auto;
    padding: 0 4px; 
    margin-left: 5px;  
}

.offer-modal-bottom .notes{
    padding: 10px 20px;
    font-size: 0.7em;
    color: #8b76a0;
}

.offer-modal-bottom .steps{
    margin-bottom: 20px;
}

.offer-modal-bottom .step{
    display: flex;
    align-items: center;
    color: #5A4D69;
    font-size: 0.9em;
}

.offer-modal-bottom .number{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: 600;
    color: #A4A4A8;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #DFDFE3;
    flex-shrink: 0;
    margin: 10px 20px 10px 0px;
}

.offer-modal-bottom .line{
    width: 5px;
    height: 100%;
    border-radius: 3px;
    background-color: #DFDFE3;

}

.offer-modal-bottom .line-wrapper{
    display: flex;
    justify-content: center;
    width: 36px;
    height: 100%;
}

.offer-modal-bottom .step-connect{
    height: 30px;
}

.offer-modal-bottom .cancel{
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    text-decoration: underline;
    color: #8c8c8c;
    font-size: 0.8em;
}

@media (max-width: 400px ){
    .offer{
        width: max(200px, 100%);
        height: 270px;
        /* font-size: 18px; */
        margin: 0;
    }

    .offer-modal-inner-wrapper{
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow: scroll;
        justify-content: flex-start;
        background-color: white;
    }

    .offer-modal-top{
        border-radius: 0px;
        height: auto;
        /* flex-direction: column; */
    }

    .offer-modal-top .right{
        /* text-align: center;
        margin-top: 20px; */
        display: none;
    }

    .offer-modal-bottom{
        height: auto;
        padding: 20px;
    }

    .offer-modal-inner-wrapper .main{
        width: 100%;
    }

}

@media (max-width: 400px) {
    .offer-modal-bottom .line-wrapper{
        width: 30px;
    }

    .offer-modal-bottom .number{
        width: 30px;
        height: 30px;
    }
}




