.settings-wrapper{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2em 15%;
  --toastify-toast-min-height: 40px;
  --toastify-color-info: #8075FF;
  --toastify-color-error: #ec6f61;
  align-items: center;
}

.settings{
  max-width: 700px;
}

.settings-box{
  width: 100%;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  padding: 2.5em 2em;
}

.settings-title{
  font-size: 1.5em;
  margin-bottom: 0.1em;
}

.settings-title-small{
  display: none;
}

.settings-desc{
  font-size: 0.8em;
  color: #8B88A7;
  margin-bottom: 1.5em;
}

.settings-desc-small{
  display: none;
}

.settings-section-title{
  font-size: 0.8em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.settings-section-value-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-section{
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid #DFDFE3;
}

.settings-section-last{
  padding-bottom: 0em;
  margin-bottom: 0em;
  border-bottom: none;
}

.settings-section-value{
  font-size: 0.8em;
  color: #8B88A7;
  width: calc(100% - 2em);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settings-section-edit-verify{
  display: flex;
}

.settings-section-value-extra-padding{
  width: calc(100% - 7em);
}

.settings-section-edit{
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #8075FF;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1em;
}

.settings-section-edit:hover{
  opacity: 0.7;
}

.settings-section-edit-disabled{
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #d3d3d6;
  text-decoration: underline;
  margin-left: 1em;
}

.settings-expansion{
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.settings-expanded{
  height: auto;
  max-height: 1000px;
}

.settings-expansion-inner{
  /* padding-bottom: 2em; */
  width: 100%;
}

.settings-expansion-section{
  margin-top: 1.5em;
}

.settings-expansion-section-title{
  font-size: 0.8em;
  padding: 0.7em 0;
}

.settings-expansion-section-input{
  width: 100%;
  display: block;
  /* margin-bottom: 40px; */
  padding: 7px 10px;
  border: 1px solid;
  border-color: rgb(213, 213, 213);
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  font-family: Helvetica;
  font-size: 0.8em;
  line-height: 1.5rem;
  resize: none;
}

.settings-expansion-section-input:focus{
  border: solid 1px #8075FF;
  outline: none;
}

.settings-save{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5em;
}

.settings-input-error{
  border: #ec6f61 solid 1px;
}

.settings-input-error-text{
  color: #ec6f61;
  font-size: 0.7em;
  padding-top: 0.5em;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.settings-input-error-text-expanded{
  max-height: 50px;
}

.settings-expansion-password-wrapper{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.settings-expansion-show-password{
  position: absolute;
  height: 1.2em;
  width: 1.2em;
  cursor: pointer;
  right: 0.9em;
  color: #d3d3d6;
}

.settings-expansion-password-input{
  padding: 7px 3.75em 7px 10px;
}

.not-verified{
  font-size: 0.8em;
  /* margin: 0.3em 0 0.2em 0; */
  height: 1.5em;
  margin: 0 1em;
  padding: 0 1em;
  border-radius: 0.75em;
  border: #F8B9B9 solid 1px;
  color: #F8B9B9;
  display: flex;
  align-items: center;
}

.settings-delete-desc{
  font-size: 0.8em;
  color: #8B88A7; 
  margin-bottom: 1.5em;
}

.settings-delete-button{
  width: 100%;
  padding: 0.7em 0;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: #F8B9B9 solid 1px;
  color: #F8B9B9;
  font-weight: 600;
  transition: 0.5s;
  cursor: pointer;
}

.settings-delete-button:hover{
  color: white;
  background-color: #F8B9B9;
}

@media (max-width: 500px ){
  .settings-wrapper{
    padding: 0;
  }

  .settings{
    margin: 10px;
  }

  .settings-box{
    padding: 25px 20px;
  }

  .settings-section{
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
  }

  .settings-section-last{
    padding-bottom: 0em;
    margin-bottom: 0em;
    border-bottom: none;
  }

  .settings-title{
    display: none;
  }

  .settings-desc{
    display: none;
  }

  .settings-title-small{
    display:  flex;
    font-size: 1.2em;
    margin-bottom: 0.1em;
    font-weight: 600;
  }

  .settings-desc-small{
    display:  flex;
    font-size: 0.8em;
    color: #8B88A7;
    margin-bottom: 3em;
  }
}