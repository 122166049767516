@keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  
.loader {
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.scan-button {
    padding: 1rem 2rem;
    font-size: 14px;
    color: #0deeb6;
    font-weight: 600;
    border-radius: 17.5px;
    background-color: transparent;
    border: 1px solid #0deeb6;
    height: 35px;
    margin-top: 5px;
    cursor: auto;    
    width: 100%;
}

.scan-button-loading{
    padding: 1rem 2rem;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 17.5px;
    background-color: #8075FF;
    height: 35px;
    margin-top: 5px;
    cursor: auto;    
    width: 100%;
    border: none;
}

.scan-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.scan-button-loading > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.updating-text{
    margin-left: 10px;
}

.button {
    padding: 1rem 2rem;
    /* font-size: 14px; */
    color: white;
    font-weight: 600;
    border-radius: 8px;
    background-color: #8075FF;
    height: 35px;
    margin-top: 5px; 
}

.button-loading{
    background-color: #b2abff;
}

.button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.confirm-button {
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 40px;
    width: 220px;
    cursor: pointer; 
    transition: opacity .4s;
    background-color: #aca4ff;
    color: white;
    flex-shrink: 0;
}

.confirm-button:hover{
    opacity: 70%;
}

.confirm-button-loading{
    opacity: 70%;
}

.confirm-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.load-more-button {
    padding: 1rem 2rem;
    font-size: 0.8em;
    color: #aba8c3;
    font-weight: 600;
    border-radius: 10px;
    height: 35px;
    background-color: white;
    border: 1px solid #aba8c3;
    width: 200px;
    margin: 20px calc(50% - 100px) 0 calc(50% - 100px);
}

.load-more-button-loading{
    opacity: 70%;
}

.load-more-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.transactions-inner-wrapper .load-more-button {
    background-color: transparent;
}