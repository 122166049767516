.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.ql-snow .ql-picker .ql-font {
  width: 150px !important;
}

.edit-scan-main-left-toolbar{
  height: 250px;
  background-color: 246, 245, 248, 1;
  
  display: flex;
  /* align-items: top; */
  position: absolute;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 200px;

  /* hide scroll bar */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  
}

/* hide scroll bar */
.edit-scan-main-left-toolbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.edit-scan-section-detail-container{
  overflow-y: scroll;
  height: 100%;
  padding: 20px 20px 0 20px;
  width: 300px;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;

  /* hide scroll bar */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.edit-scan-section-detail-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* .ql-formats button {
  width: 0;
} */

.ql-toolbar {
  /* display: flex;
  align-items: center; */
  /* white-space: nowrap; */
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  border: none !important;
  /* overflow: hidden; */
  /* display: none !important; */
}

#toolbar{
  padding-left: 20px;
  white-space: nowrap;
}

/* .ql-list{
  overflow: hidden !important;
} */

.ql-formats{
  padding: 5px 0;
  /* will be overwritten when style sheet loaded */
  display: none;
}

.section-detail-header-text-small{
  font-size: 12px; 
  color: #767676; 
  text-align: left;  
  margin-top: 3px;
}

.edit-scan-editor-wrapper{
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-top: 1px solid #DFDFE3;
}

.edit-scan-main-left-content{
  height: calc(100% - 50px);
  display: flex;
  position: relative;
  top: 50px;
  width: 100%;
  /* overflow-y: scroll; */
}

.edit-scan-editor-container .ql-editor{
  padding: 0.6in;
  min-height: 11in;

}

.ql-container{
  font-size: 10px !important;
}

/* prevent scrolling on paste */
.ql-clipboard {
  position: fixed !important;
} 


@media (max-width: 600px){
  .edit-scan-container{
    display: none;
  }

  .edit-scan-account-circle{
    display: none;
  }

  .edit-scan-header{
    height: 50px;
  }

  .edit-scan-header-download{
    border: #8075FF 1px solid;
    background-color: transparent;
    color: #8075FF;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
  }

  .edit-scan-main{
    height: calc(100% - 50px);
  }

  .edit-scan-header-back{
    display: none;
  }

  .edit-scan-header-left-main{
    margin-left: 10px;
  }
}

@page {
  size: 8.5in 11in;
  /* specifying margin here causes issues, requires further testing */
  /* chrome display issue safari no suppprt */
}

@media print {

  * { overflow: visible !important; } 

  .header-container{
    display: none;
  }

  #left-bar-outlet{
    width: 100%;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    /* height: auto !important; */
    overflow: visible !important;
    position: relative;
  }

  .edit-scan-header{
    display: none;
  }

  .scan-right-bar-container{
    display: none;
  }

  .edit-scan-main-left{
    width: 100%;
  }

  .edit-scan-main-left-toolbar{
    display: none;
  }

  .edit-scan-section-detail-container{
    display: none;
  }

  .edit-scan-main-left-content{
    top: 0;
    border: none;
    height: auto;
  }

  .edit-scan-editor-wrapper{
    border: none;
  }

  .edit-scan-editor-container{
    /* width: 8.5in; */
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .edit-scan-main{
    height: auto;

  }

  .edit-scan-editor-container .ql-container{
    /* font-size: 285% !important; */
    /* padding: 0;
    margin: 0;
    top: 0; */
  }

  .edit-scan-editor-container .ql-editor{
    padding: 0.2in;
    min-height: 0;
    /* 0.6 - 0.4 = 0.2, 0.4 is chrome default margin */
    /* border: #767676 3px solid; */
  }

  /* prevent being hidden because width < 800px
  .edit-scan-container{
    display: block;
  }   */
}