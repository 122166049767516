.dropdown-menu {
    list-style-type: none;
    padding: 0;
    margin-top: 6px;
    position: absolute;
    width: 100%;
    background-color: white;
    /* color: black; */
    /* padding: 10px 0; */
    box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
    /* top: 36px; */
    z-index: 999;
    border-radius: 5px;
    font-size: 0.8em;
    max-height: 150px;
    overflow: scroll;
}

.dropdown-menu-loading{
    padding: 0;
    margin-top: 6px;
    position: absolute;
    width: 100%;
    background-color: white;
    box-shadow: 1px 2px 10px rgba(0,0,0,0.35);
    z-index: 999;
    border-radius: 5px;
    font-size: 0.8em;
    height: 150px;  
}
  
.dropdown-menu li {
    cursor: pointer;
    padding: 8px 5px 8px 10px;
}
  
.dropdown-menu li:hover {
    background-color: #f2f2f2;
}