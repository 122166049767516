/* applies to everything without specification
do NOT use for font-size etc*/
/* scrolling bug on ios? AVOID VH it is wrong, use 100%*/
/* modal inner container should have justify content flex-start */
/* without display flex children will be 100% parent size */
*{
  box-sizing: border-box;
}

a{
  cursor: pointer;
}

body{
  color: rgb(90, 77, 105);
  font-size: 18px;
}
main{
  margin: 0 auto;
}

.disabled{
  cursor: not-allowed;
}

.required{
  color: #ec6f61;
}

header{
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 25px;
  margin-top: 25px; */
  align-items: center;
  padding: 18px 10% 18px 10%;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 5;
  transition: 0.5s ease-in-out;
  color: white;
  font-weight: 600;
  font-size: 0.9em;
}

.header-scroll{
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  color:rgb(64, 56, 73);
}

header a{
  text-decoration: none;
  color: inherit;
  padding-right: 15px;
}

header span{
  text-decoration: none;
  color: inherit;
  padding-right: 15px;
}

.clickable{
  cursor: pointer;
}

.hidden{
  display: none;
}

.not-bold{
  font-weight: normal;
}

.logo{
  height: 25px;
  width: auto;
  color: rgb(64, 56, 73);
  margin-top: 5px;
}

.logo-white{
  height: 25px;
  width: auto;
  color: rgb(255, 255, 255);
  margin-top: 5px;
}

header nav{
  display: flex;
  gap: 15px;
}

div.template{
  display: flex;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
  background-color: white;
  width: 200px;
  height: 250px;
}

div.create-new-template-box{
  display: flex;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
  background-color: white;
  width: 200px;
  height: 250px;
}

div.templateWrapper{
  padding: 25px 0px;;
  margin: 40px;
  overflow: hidden;
} 

div.create-new-template-box-wrapper{
  padding: 25px 0px;
  margin: 40px;
  overflow: hidden;
}

/* div.template-area-outer-wrapper{
  justify-content: center;
} */

div.template div.texts h2{
  margin:0;
  text-decoration: none;
  font-size: 1.6rem;
}

div.template div.texts a{
  text-decoration: none;
  color: inherit;
}

div.template p.info{
  margin: 6px 0;
  color: #888;
  font-size: .7rem;
  font-weight: bold;
  display: flex;
  gap: 10px;
}

div.template p.summary{
  margin: 10px 0;
  line-height: 1.4rem;
}


/* input{
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding: 8px 0px 8px 20px;
  border: 2px solid;
  border-color: rgb(213, 213, 213);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  font-family: Helvetica;
} */

button{
  width: 100%;
  display: block;
  background-color: #8075FF;
  border: 0;
  color: #fff;
  border-radius: 20px;
  padding: 10px 0;
  cursor: pointer;
  /* font-size: 18px; */
}

btn-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

a svg{
  height: 24px;
}

form.upload_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 18vh; */
}

.upload-form-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
}


div.form-container {
  width: min(max(65vw, 370px),530px);
  max-height: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  overflow: scroll;
}

div.form-inner-container{
  width: max(100%,300px);
  /* height: max(100%,550px); */
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

div.form-container div.header {
  /* flex: 20%; */
  display: grid;
  place-items: center;
}

div.form-container div.form-body {
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2em 0 1em 0;
  align-items: center;
  /* flex: 80%; */
}

div.form-container div.form-body-justify-center{
  justify-content: center;
}

div.form-container div.form-footer {
  width: calc(100% - 7em);
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  height: 70px;
  bottom: 30px;
  position: absolute;
}

div.form-container div.form-footer button {
  border-radius: 20px;
  width: 100%;
  height: 40px;
  background-color: #8075FF;
  font-weight: bold;
  color: white;
  border: 0 none;
  cursor: pointer;
  padding: 10px 5px;
  font-size: 0.8em;
}

div.form-container div.form-footer button.next-previous-button {
  background-color: #ffffff;
  color: #8075FF;
  border: 1px solid #8075FF;
}

div.form-container div.form-footer button.left-space {
  margin-left: 5px;
}

div.form-container div.form-footer button.right-space {
  margin-right: 5px;
}

div.form-container div.form-footer button:disabled {
  background-color: #EFF0F6;
  color: #A4A4A8;
  cursor: not-allowed;
  border: none;
}

/* PROGRESS BAR */
.progress-bar {
  width: calc(100% - 7em);
  height: 8px;
  background-color: rgb(230, 230, 230);
  margin: 50px 0 40px 0;
  border-radius: 5px;
}

div.progress-bar div {
  width: 33.3%;
  height: 8px;
  background-color: #8075FF;
  border-radius: 5px;
  transition: 0.5s;
}

.second-form-container{
  width: calc(100% - 7em);
  height: 100%;
}

.second-form-job-title{
  display: block;
  margin-bottom: 10px;
  height: 36px;
  width: 100%;
  padding: 8px 0px 8px 20px;
  border: 2px solid;
  border-color: rgb(213, 213, 213);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  font-family: Helvetica;
  font-size: 0.8em;
}

div.second-form-container textarea.job-desc{
  height: calc(100% - 46px);
  border-radius: 20px;
  resize: none;
  padding: 15px 20px 15px 20px;
  margin-right: 0px;
  font-size: 0.8em;
}

.match{
  border-radius: 0.9em;
  /* height: 1.8em; */
  width: auto;
  background-color: #E7E5FE;
  color: #8075FF;
  font-size: 0.8em;
  font-weight: 600;
  border: 0 none;
  padding: 5px 20px;
  margin: 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* .not-match-container{
  border-radius: 16px;
  height: 32px;
  width: auto;
  background-color: #DFDFE3;
  color: #767676;
  font-weight: 600;
  border: 0 none;
  padding: 10px 20px;
  margin: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.not-match{
  border-radius: 0.9em;
  /* height: 1.8em; */
  width: auto;
  background-color: #DFDFE3;
  color: #767676;
  font-size: 0.8em;
  font-weight: 600;
  border: 0 none;
  padding: 5px 20px;
  margin: 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

div.match-area-container{
  width: 80%;
}

div.editor{
  height: 90%;
  color: #333;
}

div.score-container div.number{
  font-weight: 600;
}

circle.circle-background{
  fill: none;
  stroke: #ddd;
}

circle.circle-progress{
  fill: none;
  stroke: #8075FF;
  stroke-linecap: round;
  stroke-linejoin: round;

}

svg.svg-circle{
  display: block;
  margin: auto;
}

text.circle-text{
  font-weight: 600;
  /* font-size: 30px; */
  fill: #8075FF;
  /* visibility: visible; */
}

div.score-container{
  padding-bottom: 15px;
}

textarea{
  display: block;
  /* margin-bottom: 10px; */
  width: 100%;
  padding: 15px 20px 15px 20px;
  border: 2px solid;
  border-color: rgb(213, 213, 213);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  font-family: Helvetica;
}

.first-form-container{
  width: calc(100% - 7em);
  height: 100%;
}

.drag-container{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 3px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #DFDFE3;
  background-color: #ffffff;
  transition: 0.5s;
  
}

.drag-active {
  background-color: #F6F5FF;
}

#input-file-upload {
  display: none;
}

#cloud_icon{
  width: 6em;
}

#check_icon{
  width: 3.5em;
  margin-bottom: 5px;
  color:#8075FF;
}

.upload-button{
  width: calc(50% + 10px);
  margin-top: 1em;
  font-size: 0.8em;
  display: block;
  background-color: #8075FF;
  border: 0;
  color: #fff;
  border-radius: 20px;
  padding: 10px 0;
  cursor: pointer;
}

/* .upload-button-disabled{
  width: calc(50% + 10px);
  margin-top: 1em;
  font-size: 0.8em;
  background-color: #ccc;
} */

#form-title{
  font-size: 1.4em;
  color: #8075FF;
  margin: 0px 0px 10px 0px;
}

.form-desc{
  font-size: 0.8em;
  color: #767676;
  /* width: 60%; */
  text-align: center;
  white-space: nowrap;
  text-align: center;
}

.form-desc-container{
  display: flex;
  flex-direction: column;
}

#drag-file-element {
  position: absolute;
  width: 400px;
  height: 230px;
  border-radius: 1rem;
}

#drag-inner-container {
  position: absolute;
  height: 230px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 3px;
  border-radius: 1rem;
}

.drag-box-text {
  font-size: 0.8em;
  color: #767676;
  text-align: center;
}

.file-uploded-text {
  font-size: 0.8em;
  color: #767676;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3px;
}

.filename-text {
  font-size: 0.8em;
  color: #767676;
  text-align: center;
  padding: 0 5px;
}

#icon_text_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50%;
}

.results-summary{
  width: 100%;
  /* height: 500px; */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.results-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
}

.results-container{
  width: max(min(900px, 65vw), 300px);
}

#results-rescan{
  align-items: center;
  display: flex;
  width: 80px;
}

.results-rescan-icon{
  height: 1.4em;
  flex-shrink: 0;
}

#rescan-text{
  margin-left: 5px;
  font-size: 0.9em;
  color: #767676;
}

#results-left-bar{
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#results-right-bar{
  /* background-color: #767676; */
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

#results-main{
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 20px 50px 20px;
}

#your-resume-score-title{
  font-size: 1.3em;
  font-weight: 600;
  color: #767676;
  margin-bottom: 20px;
}

#result-comment{
  width: 50%;
  text-align: center;
  color: #767676;
  font-size: 0.8em;
}

#scan-to-create{
  width: 200px;
  height: 35px;
  background-color: #8075FF;
  border: 0;
  color: #fff;
  border-radius: 20px;
  padding: 10px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  margin-top: 30px;
  font-size: 0.9em;
}

#scan-to-create-disabled {
  width: 200px;
  height: 35px;
  border: 0;
  border-radius: 20px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  margin-top: 30px;
  font-size: 0.9em;
  background-color: #EFF0F6;
  color: #A4A4A8;
  cursor: not-allowed;
}

/* #results-scan-to-create-disabled{
  width: 200px;
  height: 35px;
  border: 0;
  border-radius: 20px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  display: none;
  text-decoration: none;
  margin-top: 30px;
  font-size: 0.9em;
  background-color: #EFF0F6;
  color: #A4A4A8;
  cursor: not-allowed;  
} */

#results-top-bar{
  padding: 30px 30px 0px 30px;
  width: 100%;
}

.results-section-button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 10px 0px;
  width: 320px;
  height: 80px;
  padding: 8px 0px 8px 0px;
  border: 1px solid;
  border-color: rgb(213, 213, 213);
  border-radius: 20px;
  font-family: Helvetica;
  background-color: white;
  flex-shrink: 0;
  cursor: pointer;
  /* font-size: 18px; */
}

.results-section-button-selected{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 10px 0px;
  width: 320px;
  height: 80px;
  padding: 8px 0px 8px 0px;
  border: 1px solid;
  border-color: rgb(213, 213, 213);
  border-radius: 20px;
  font-family: Helvetica;
  background-color: #F6F5FF;
  cursor: pointer;
}

.results-section-score-circle{
  background-color: #E7E5FE;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.results-section-score{
  font-size: 1.1em;
  color: #8B88A7;
  font-weight: 600;
}

.results-section-middle{
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 20px;
  width: 170px;
  /* font-size: 18px; */
}

.results-section-text-title{
  font-size: 0.9em;
  color: #767676; 
  font-weight: 600;
  text-align: left;
}

.results-section-text-desc{
  font-size: 0.8em; 
  color: #767676; 
  text-align: left;
}

/* PROGRESS BAR */
.score-bar {
  width: 100%;
  height: 8px;
  background-color: rgb(230, 230, 230);
  margin-top: 5px;
  border-radius: 5px;
}

div.score-bar div {
  width: 33.3%;
  height: 8px;
  background-color: #8075FF;
  border-radius: 5px;
}

.results-section-arrow{
  width: 30px;
  height: 30px;
  color: #D1D1D1;
  margin: 0px 0px 0px 0px;
}

#results-section-detail-title{
  width: 100%;
  margin: 30px 0px 30px 0px;
  font-size: 1.1em;
  color: #767676;
}

.section-detail-overview{
  width: 100%;
  /* height: 215px; */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 50px 30px 50px;
  margin-bottom: 35px;
}

.section-detail-score-circle{
  background-color: #E7E5FE;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 20px;
}

.section-detail-score{
  font-size: 25px;
  color: #8075FF;
  font-weight: 600;
}

.section-detail-leftbar{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max(18%, 150px);
  margin-right: 50px;
  flex-shrink: 0;
}

.section-detail-leftbar-title{
  font-size: 1em;
  color: #767676; 
  font-weight: 600;
  text-align: center;
}

.section-detail-leftbar-text{
  font-size: 0.8em; 
  color: #767676; 
  text-align: center;
}

.section-detail-rightbar-title{
  font-size: 0.8em; 
  color: #767676; 
  font-weight: 600;
}

.section-detail-rightbar-text{
  font-size: 0.8em; 
  color: #767676; 
  /* width: 600px; */
  /* margin-bottom: 20px; */
}

#section-detail-rightbar{
  display: flex;
  flex-direction: column;
}

.text-spacing{
  height: 15px;
}

.section-detail{
  width: 100%;
  /* height: 215px; */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 35px 50px 50px 50px;
  margin-bottom: 35px;
}

.section-detail-matched-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-detail-missing-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section-detail-matched-title{
  text-align: center;
  margin: 10px;
  font-weight: 600;
  color: #767676; 
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-detail-missing-title{
  text-align: center;
  margin: 10px;
  font-weight: 600;
  color: #767676; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-detail-matched{
  flex: 1;
}

.section-detail-missing{
  flex: 1;
}

.suggestion{
  width: 100%;
  /* height: 215px; */
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 15px 0px 15px 0px;
  margin-bottom: 35px;
  flex-direction: column;
}

.suggestion-top-bar{
  padding: 0px 30px 0px 30px;
  width: 100%;
}

.suggestion-type{
  font-size: 0.8em;
  color: #D1D1D1; 
  font-weight: 600;
}

.suggestion-title-container{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.suggestion-title{
  font-size: 0.9em;
  color: #767676; 
  font-weight: 700; 
  margin-left: 5px; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion-title-remove{
  font-size: 0.9em;
  color: #767676; 
  font-weight: 700; 
  margin-left: 5px; 
  text-decoration: line-through;
  text-decoration-color: #F8B9B9;
}

.suggestion-main{
  display: flex;
  flex-direction: column;
  padding: 12px 50px 12px 50px;  
}

.suggestion-icon-success{
  display: flex;
  /* align-items: center; */
  color: #A2D4AD;
}

.suggestion-icon-fail{
  display: flex;
  /* align-items: center; */
  color: #F8B9B9;
}

.suggestion-desc{
  font-size: 0.9em;
  overflow: hidden;
}

.left-bar{
  /* min-height: 500px; */
  height: 100%;
  width: 80px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  padding: 15px 0 20px 0;
  padding-left: 0px;
  overflow: scroll;
  box-shadow: 10px 0px 10px -8px rgba(0,0,0,0.1);
}

.left-bar-expanded{
  width: 230px;
  padding-left: 8px;
}

.left-bar-button{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #9085a5;
  padding: 3px 0 3px 27px;
}

.left-bar-button:hover {
  color:#8075FF;
  transition: 0.5s;
}

.left-bar-text{
  margin-left: 27px;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
}

.left-bar-selected{
  border-right: 3px solid;
  color: #8075FF;
}

#left-bar-top{
  display: flex;
  flex-direction: column;
  text-align: left;
}

#left-bar-bottom{
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: left;
  color: #9a9a9a;
  padding-left: 27px;
}

#left-bar-bottom:hover {
  color:#c03c7e;
  transition: 0.5s;
}

.left-bar-top-icon{
  height: 26px;
  margin: 10px 0px 10px 0px;
  flex-shrink: 0;
}

#left-bar-logout-icon{
  height: 26px;
  margin: 10px 0px 10px 0px;
  flex-shrink: 0;
}

.left-bar-top-logo-wrapper{
  margin: 15px 0px 25px 25px;
  width: 30px;
}

#min-left-bar-logo{
  color: #8075FF;
}

#left-bar-logo{
  color: #8075FF;
  margin: 15px 0px 25px 0px;
  width: 30px;
  margin-left: 25px;
}

.left-bar-close{
  width: 25px;
  color: #8B88A7;
  cursor: pointer;
  display: none;
  margin-right: 25px;
}

.min-left-bar{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  /* overflow-x: hidden; */
  display: none;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  transition: width 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  padding: 15px 0 20px 0;
  padding-left: 0px;
  overflow: scroll;
  box-shadow: 10px 0px 10px -8px rgba(0,0,0,0.1);
}

.min-left-bar-expanded{
  width: 230px;
  padding-left: 8px;  
}

.min-left-bar-button{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #9085a5;
  padding: 0 0 0 27px;
}

.min-left-bar-button:hover {
  color:#8075FF;
  transition: 0.5s;
}

.min-left-bar-text{
  margin-left: 27px;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
}

.min-left-bar-selected{
  color: #8075FF;
}

#logo-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-icon{
  height: 35px;
  width: 30px;
  margin-top: 5px;
}

#left-bar-outlet{
  left: 80px;
  background-color: rgb(246, 245, 248);
  height: 100%;
  width: calc(100vw - 80px);
  position: fixed;
  overflow: scroll;
}

/* don't change */
#header-outlet{
  /* overflow: scroll; */
  height: 100vh;
  width: 100vw;
  /* position: fixed; */
}

#header-outlet-no-scroll{
  overflow: hidden;
  position: fixed;
}

#dashboard-historyWrapper{
  margin: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
} 

#history-logo-wrapper{
  height: 60px;
  width: 60px;
  border-radius: 15px;
  /* background-color: #dadadf; */
  border: #E6E6E6 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#dashboard-history-logo-wrapper{
  height: 50px;
  width: 50px;
  border-radius: 15px;
  border: #E6E6E6 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#history-company-logo{
  max-height: 40px;
  max-width: 40px;
}

#dashboard-history-company-logo{
  max-height: 30px;
  max-width: 30px;  
}

#history-logo-and-score{
  margin-right: 15px;
}

#history-left-bar{
  display: flex;
  width: calc(100% - 100px);
}

#history-score{
  border-radius: 16px;
  height: 25px;
  width: auto;
  background-color: #E7E5FE;
  color: #8075FF;
  font-weight: 600;
  border: 0 none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#history-title{
  font-size: 20px;
  font-weight: 600;
}

#history-company-name{
  font-size: 15px;
  margin-bottom: 8px;
}

.icons-bar{
  display: flex;
  /* align-items: center; */
}

#history .history-icon{
  width: 1.1em;
  margin-right: 0.5em;
  flex-shrink: 0;
  color: #8B88A7;
}

#history .history-icon-wrapper{
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  font-size: 0.75em;
  color: #8B88A7;
}

.dashboard #history-description{
  font-size: 0.8em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

#history-text{
  width: calc( 100% - 75px );
  justify-content: space-between;
}

#history-text-top{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}

#history .history-button{
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  margin: 0 0 10px 10px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
}

#history .edit{
  border: #8075FF solid 1px;
  color: #8075FF;
  text-decoration: none;
}


#history .edit:hover{
  border: #8075FF solid 1px;
  background-color: #8075FF;
  color: white;
}

#history .delete{
  border: #8B88A7 solid 1px;
  color: #8B88A7;
}

#history .delete:hover{
  border: #8B88A7 solid 1px;
  background-color: #8B88A7;
  color: white;
}

.skeleton {
  opacity: .7;
  animation: skeleton-loading 0.7s linear infinite alternate;
}


.skeleton-text {
  width: 100%;
  height: .7em;
  margin-bottom: .5em;
  border-radius: .125em;
}

.skeleton-text-no-margin {
  width: 100%;
  height: .7em;
  border-radius: .125em;
}

.last-line {
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(254, 20%, 70%);
  }
  100% {
    background-color: hsl(254, 20%, 90%);
  }
}

#history-company-skeleton{
  margin-bottom: 1.2em;
  width: 50%;
}

.edit-scan-container{
  overflow: hidden;
  /* width not relative to parent becaused position: fixed */
  position: absolute; 
  /* width: calc(100% - 80px); */
  width: 100%;
  height: 100%;
}

.edit-scan-header{
 height: 85px;
 box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
 font-size: 12px;
 display: flex;
 justify-content: space-between;
 z-index: 5;
 background-color: rgba(246, 245, 248, 1);
 flex-shrink: 0;
 position: relative; 
 /* width: 100% will be relative to parent and not exceed screen length */
 top: 0;
 width: 100%;
}

.edit-scan-main{
  width: 100%;
  height: calc(100% - 85px);
  display: flex;
  position: absolute;
}

.edit-scan-main-left{
  height: 100%;
  display: flex;
  flex-direction: column;
  /* take up remaining space */
  flex: 1; 
  font-size: 12px;
  width: calc(100% - 220px);
  position: absolute;
}

.edit-scan-header-title-wrapper{
  width: 100%;
  display: flex;
}


.edit-scan-header-title{
  font-size: 20px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100%; */
  cursor: pointer;
  transition: color 0.5s;
}

.edit-scan-header-title:hover{
  color: #8B88A7;
}

.edit-scan-header-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 367px);
}

.edit-scan-header-left-main{
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  width: calc(100% - 80px);
}

.edit-scan-header-company{
  font-size: 15px;
  color: #333;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-scan-header-company-container{
  display: flex;
  align-items: center;
}

.edit-scan-header-company-edit{
  width: 20px;
  color: #A4A4A8;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;
  flex-shrink: 0;
}

.edit-scan-header-right{
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.edit-scan-save-template{
  border-radius: 16px;
  height: 35px;
  width: 160px;
  background-color: transparent;
  color: #8075FF;
  border: 1px solid #8075FF;
  padding: 10px 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  flex-shrink: 0;
}

.edit-scan-save-template:hover{
  opacity: 70%;
}

.edit-scan-save-template-loading{
  opacity: 70%;
}

.edit-scan-save-template > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.edit-scan-header-download{
  border-radius: 16px;
  height: 35px;
  width: auto;
  background-color: #8075FF;
  color: #ffffff;
  border: 0 none;
  padding: 10px 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  transition: 0.5s;
}

.edit-scan-header-download:hover{
  opacity: 70%;
}

.edit-scan-account-circle{
  background-color: #C5BEFF;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 20px;
  text-align: center;
  flex-shrink: 0;
  border: none;
  text-decoration: none;
}

.edit-scan-account-text{
  font-size: 20px;
  color: #8075FF;
  font-weight: 600;
}

#edit-scan-header-arrow{
  width: 40px;
  height: 40px;
  color: #A4A4A8;
}

.edit-scan-header-back{
  margin: 0px 10px 0px 10px;
  background-color: transparent;
  width: 40px;
  border: none;
}

.edit-scan-editor-container{
  /* a full page width is 2550 pixels */
  width: 8.5in;
  flex-shrink: 0;
  /* overflow-x: scroll; */
  padding: 20px 20px 20px 20px;
  /* flex-shrink: 0; */
}

div.scan-right-bar-container{
  height: 100%;
  width: 220px;
  background-color: white;
  right: 0;
  top: 0;
  flex-shrink: 0;
  position: absolute;
  /* box-shadow: -4px 0 4px rgba(0, 0, 0, 0.1); */
  border-left: 1px solid #DFDFE3;

}

.scan-right-bar{
  height: 100%;
  overflow: auto;
  padding: 10px 20px 0px 20px;
}

.scan-right-bar-title{
  text-align: center;
  font-weight: 500;
  margin: 12px 0 14px 0;
  font-size: 15px;
  color: #333;
}

.scan-right-bar-result-title{
  text-align: center;
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.scan-right-bar-comment{
  text-align: center;
  margin: 10px 0 20px 0;
  font-size: 13px;
  color: #333;
}

.section-button-small{
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 10px 0px;
  width: 100%;
  height: 60px;
  padding: 8px 0px 8px 0px;
  border-radius: 10px;
  font-family: Helvetica;
  background-color: white;
  border: 1.5px solid transparent;
  transition: 0.5s;
}

.section-button-small:hover{
  background-color: #F6F5FF;
}

.section-button-small-selected{
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 10px 0px;
  width: 100%;
  height: 60px;
  padding: 8px 0px 8px 0px;
  border: 1.5px solid #c1bee4;
  border-radius: 10px;
  font-family: Helvetica;
  background-color: #F6F5FF;
}

.scan-right-bar-section-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}

#scan-right-bar-button-icon{
  height: 20px;
  margin: 5px;
}

.section-small-score-circle{
  background-color: #E7E5FE;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-left: 10px;
}

.section-small-score{
  font-size: 16px;
  color: #8B88A7;
  font-weight: 600;
}

.section-small-middle{
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 10px;
  width: 105px;
}

.section-small-text-title{
  font-size: 13px; 
  color: #767676; 
  font-weight: 600;
  text-align: left;
}

.section-small-text-desc{
  font-size: 12px; 
  color: #767676; 
  text-align: left;
}

/* PROGRESS BAR */
.small-score-bar {
  width: 100%;
  height: 5px;
  background-color: rgb(230, 230, 230);
  margin-top: 5px;
  border-radius: 5px;
}

div.small-score-bar div {
  width: 33.3%;
  height: 5px;
  background-color: #8075FF;
  border-radius: 5px;
}

.match-small{
  border-radius: 16px;
  /* height: 32px; */
  width: auto;
  background-color: #E7E5FE;
  color: #8075FF;
  font-weight: 700;
  border: 0 none;
  padding: 6px 16px;
  margin: 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
}

.not-match-small{
  border-radius: 16px;
  /* height: 32px; */
  width: auto;
  background-color: #DFDFE3;
  color: #767676;
  font-weight: 700;
  border: 0 none;
  padding: 6px 16px;
  margin: 6px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
}

.section-detail-small{
  width: 100%;
  /* height: 215px; */
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px 20px 20px 20px;
  margin-bottom: 20px;
}

.section-detail-title-small{
  text-align: center;
  margin: 10px;
  font-weight: 600;
  color: #333;
  font-size: 15px;   
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip{
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: #8B88A7;
  font-weight: normal;
  font-size: 0.8em;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 5px);
  left: 50%;
  margin-left: -75px;
  z-index: 2;
  
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 0.5s;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.section-detail-info-icon{
  height: 18px;
  color: #8B88A7;
}

.stat-box-info-icon{
  height: 1em;
  color: #8B88A7;
}

.matched-hardskill-info-small, .missing-hardskill-info-small{
  margin-left: 5px;
  position: relative;
  display: flex;
  align-items: center;
}

.matched-hardskill-info-small:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.missing-hardskill-info-small:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


.tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.suggestion-small{
  width: 100%;
  /* height: 215px; */
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.suggestion-type-small{
  font-size: 12px;
  color: #A4A4A8; 
  margin-bottom: 14px;
}

.suggestion-title-small{
  font-size: 15px;
  color: #767676; 
  font-weight: 600; 
  margin-left: 5px; 
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion-title-remove-small{
  font-size: 15px;
  color: #767676; 
  font-weight: 600; 
  margin-left: 5px; 
  margin-top: 1px;
  text-decoration: line-through;
  text-decoration-color: #F8B9B9;
}

.suggestion-desc-small{
  font-size: 13px;
  color: #767676;
  line-height: 1.3;
  overflow: hidden;
}

.suggestion-title-container-small{
  display: flex;
  align-items: top;
  margin-bottom: 14px;
}

.section-detail-overview-small{
  width: 100%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 20px 17px 20px;
  margin-bottom: 20px;
}

.section-detail-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.section-detail-header-title{
  font-size: 16px; 
  color: #333; 
  font-weight: 600;
  text-align: left;
}

.section-detail-header-text{
  font-size: 14px; 
  color: #767676; 
  text-align: left;
}

.section-detail-score-circle-small{
  background-color: #E7E5FE;
  width: 70px;
  height: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 20px 0 15px;
  font-weight: 600;
  flex-shrink: 0;
}

.section-detail-main-title{
  font-size: 14px;
  color: #767676; 
  font-weight: 600;
}

.section-detail-main-text{
  font-size: 13px;
  color: #767676; 
  line-height: 1.3;
}

#section-detail-main{
  display: flex;
  flex-direction: column;
}


.top-menu{
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 5;
  transition: 0.5s ease-in-out;
  font-weight: 500;
  font-size: 11px;
  background-color: rgb(255, 255, 255);
  height: 55px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.top-menu .bottom-icon{
  width: 20px;
  margin-bottom: 5px;
}

.top-menu .top-menu-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #9085a5;
  cursor: pointer;
  transition: 0.5s;
  padding: 15px;
}

.top-menu .top-menu-item:hover{
  color: #8075FF;
}

.top-menu .top-menu-logo{
  color: #8075FF;
  width: 25px;
}

.leftbar-overlay{
  background: rgba(48, 45, 53, 0.7);
  height: 100%;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  width: 100%;
  z-index: 998;
  overflow: hidden;
  display: none;
  /* backdrop-filter: blur(5px); */
}

#scanner-page-container{
  /* font-size: 18px; */
  /* position: fixed;
  overflow: scroll; */
}

#demo-scanner-page-container{
  height: calc(100% - 66px); 
  position: relative;
  top: 66px;
  /* cannot use 100vh bc ios bug */
  /* width: 100vw;
  font-size: 18px;
  background-color: rgb(246, 245, 248);
  overflow: scroll; */
}

.loader-circle-container{
  position: relative;
  width: 50px;
  height: 50px;
  animation: load-rotate 3s linear infinite;
  background-color: rgb(246, 245, 248);
  border-radius: 50%;
}

.loader-container .white{
  background-color: white;
}

.loader-circle-container circle{
  animation: load-plus 3s infinite linear;
  /* border-radius: 50%; */
  stroke-width: 12;
  stroke-linecap: round;
  stroke: #c1bcff;
  stroke-dasharray: 119;
  stroke-dashoffset: 119;
  width: 100%;
  height: 100%;
  fill: none;
  transform: translate(6px, 6px);
}

.loader-logo{
  width: 51px;
  height: auto;
  position: absolute;
  top: -2.5px;
  left: 25.5px;
}

.loader-container{
  width: 100px;
  position: absolute;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-outer-container{
  width: 100%;
  height: 100%;
  position: absolute;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-container .loading-text{
  font-size: 0.8em;
  color: #c1bcff;
  font-weight: 600;
  margin-top: 25px;
  text-align: center;
}

.loader-circle-container-generic circle{
  stroke: #f2f2f2;
  animation: load-plus 3s infinite linear;
  /* border-radius: 50%; */
  stroke-width: 8;
  stroke-linecap: round;
  stroke-dasharray: 119;
  stroke-dashoffset: 119;
  width: 100%;
  height: 100%;
  fill: none;
  transform: translate(6px, 6px);
}

.loader-container-generic{
  width: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #8075FF; */
}

.loader-circle-container-generic{
  background-color: none;
  position: relative;
  width: 50px;
  height: 50px;
  animation: load-rotate 3s linear infinite;
  border-radius: 50%;
  /* background-color: red; */
}

.history-page-title{
  font-size: 1.5em;
  margin-left: 20px;
}

.desktop-only{
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8c8c8c;
  display: none;
  font-size: 0.9em;
  flex-direction: column;
  text-align: center;
}

.desktop-only-icon{
  height: 5em;
  margin-bottom: 0.5em;
  color: #ccc;
}

.desktop-only-title{
  font-size: 1em;
  margin-bottom: 0.5em;
}

.desktop-only-text{
  font-size: 0.8em;
  width: 20em;
}

.mobile-only-result{
  display: none;
}

#history-text-top-left{
  width: 100%;
}

.no-section-detail{
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #767676;
  font-size: 0.9em;
  border-radius: 20px;
  border: 2px solid #DFDFE3;
  padding: 0 20%;
  text-align: center;
}

.section-detail-small .empty-message{
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #d3d3d6;
  font-size: 14px;
}

.section-detail .empty-message{
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #d3d3d6;
  font-size: 0.9em;
}

.toast-custom-style{
  font-size: 0.8em;
  display: block;
}

.privacy-terms-page{
  margin: 1em;
}

@media (max-width: 1100px){
  #results-main{
    flex-direction: column;
  }

  #results-left-bar{
    margin-bottom: 40px;
  }

  .results-section-button{
    margin-right: 0;
  }

  .results-section-button-selected{
    margin-right: 0;
  }
}

@media (max-width: 900px ){
  .header-container .top-menu{
    display: flex;
  }

  .header-container .left-bar{
    display: none;
  }

  .header-container .min-left-bar{
    display: flex;
  }

  .header-container .leftbar-overlay{
    display: flex;
  }

  .home-header-container .top-menu{
    display: flex;
  }

  .home-header-container .header{
    display: none;
  }

  .home-header-container .header-scroll{
    display: none;
  }

  .home-header-container .min-left-bar{
    display: flex;
  }

  .home-header-container .leftbar-overlay{
    display: flex;
  }

  #left-bar-outlet{
    left: 0;
    top: 55px;
    width: 100vw;
    height: calc(100% - 55px);
  }

  #demo-scanner-page-container{
    height: calc(100% - 55px);
    top: 55px;
  }

  .section-detail-overview{
    flex-direction: column;
  }

  .section-detail-leftbar{
    margin: 0 0 35px 0;
  }

  .results-section-button{
    width: 270px;
  }

  .results-section-button-selected{
    width: 270px;
  }

  .results-section-middle{
    width: 140px;
    margin: 0 15px 0 15px;
  }

  .section-detail{
    flex-direction: column;
  }

  .section-detail-matched{
    margin-bottom: 20px;
  }

  .toast-custom-style{
    top: 65px !important;
  }
}

@media (max-width: 600px){
  .desktop-only{
    display: flex;
  }

  #scan-to-create{
    display: none;
  }

  .mobile-only-result{
    display: flex;
  }

  /* #results-scan-to-create-disabled{
    display: flex;
  } */
}

@media (max-width: 500px){
  .first-form-container{
    width: calc(100% - 5em);
  }

  .second-form-container{
    width: calc(100% - 5em);
  }
}

@media (max-width: 480px){
  .toast-custom-style{
    top: 55px !important;
  }
}

@media (max-width: 400px ){
  .min-left-bar-expanded{
    width: 100vw;
  }

  div.form-container{
    border-radius: 0;
    height: 100%;
    width: 100%;
  }

  body{
    font-size: 15px;
  }

  #results-section-detail-title{
    text-align: center;
  }

  .results-container{
    width: max(100%, 300px);
    overflow-x: hidden;
  }

  .section-detail{
    padding: 35px 20px 50px 20px;
    border-radius: 0;
  }

  /* #results-main{
    padding: 10px 15px 50px 15px;
  } */

  .section-detail-overview{
    padding: 30px 20px 30px 20px;
    border-radius: 0;
  }

  .suggestion-top-bar{
    padding: 0 20px;
  }

  .suggestion-main{
    padding: 12px 30px 12px 30px;
  }

  .results-form{
    margin-top: 0;
  }

  .results-summary{
    border-radius: 0;
  }

  .suggestion{
    border-radius: 0;
  }

  #modal-wrapper{
    font-size: 15px;
  }

  .no-section-detail{
    border: none;
  }

  .left-bar-top-logo-wrapper{
    width: calc(100% - 25px);
    display: flex;
    justify-content: space-between;
  }

  .left-bar-close{
    display: flex;
  }
}

@keyframes load-plus {
  0%, 100% {
    stroke-dashoffset: 119;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 238;
  }
}

@keyframes load-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media screen and (min-width: 768px){
  div.create-new-template-box{
    display: flex;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px){
  div.template{
    display: flex;
    margin-bottom: 40px;
  }
} */

