.benefits-page-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
}

.benefits-page-plans-container{
    display: flex;
}

.benefits-page-plan-title{
    font-size: 0.9em;
    color: #8B88A7;
    margin-bottom: 5px;
    width: 100%;  
    text-align: left;
    
}

.benefits-page-plan-price{
    width: 100%;  
    text-align: left;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;   
}

.benefits-page-plan-price-big{
    font-size: 1.7em;
    font-weight: 700;
    color: #8075FF; 
}

.benefits-page-plan-price-small{
    font-size: 0.8em;
    color: #8B88A7; 
    margin-left: 5px;
    font-weight: 600;

}

.benefits-page-plan-wrapper{
    margin: 20px;
    width: 300px;
}

.benefits-page-plan-header{
    background-color: #8075FF;
    height: 2em;
    font-size: 0.8em;
    font-weight: 600;
    color: white;
    border-radius: 10px 10px 0 0 ;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.benefits-page-wrapper .placeholder{
    background-color: transparent
}

.benefits-page-wrapper .premium-border{
    border: #8075FF 1px solid;
    border-radius: 0 0 10px 10px;
}

.benefits-page-plan{
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px white solid;
}

.benefits-page-plan-desc{
    font-size: 0.8em;
    color: #8B88A7;
    margin-bottom: 20px;  
    width: 100%;  
    text-align: left;
}

.benefits-page-plan-benefit{
    margin: 15px 0;
    display: flex;
    align-items: center;
    width: 100%;  
    text-align: left;
}

.benefits-page-plan-benefit-icon{
    margin-right: 10px;
    height: 0.8em;
    color: #8075FF;
}

.benefits-page-plan-benefit-text{
    font-size: 0.8em;
}

.benefits-page-select-button{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    font-size: 0.8em;
    margin: 20px 10px 10px 10px;
    padding: 10px;
    background-color: #8075FF;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

.benefits-page-wrapper .current-plan{
    color: #8075FF;
    border: #8075FF solid 1px;
    background-color: white;
}

.benefits-page-wrapper .disabled{
    color: #8B88A7;
    border: #8B88A7 solid 1px;
    background-color: white;
}

.benefits-page-desc{
    margin-bottom: 40px;
    color: #8B88A7;
    font-size: 0.9em;
    width: max(250px, 50%);
    text-align: center;
}

.benefits-page-title{
    margin-bottom: 20px;
    font-size: 2em;
    font-weight: 700;
    width: max(300px, 50%);
    text-align: center;   
    background: linear-gradient(to top right, #A097FF , #f58aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 800px ){
    .benefits-page-plans-container{
        flex-direction: column;
    }
}

@media (max-width: 400px){
    .benefits-page-wrapper{
        padding: 60px 10px 10px 10px;
    }

    .benefits-page-plans-container{
        width: 100%;
    }

    .benefits-page-plan-wrapper{
        width: 100%;
        margin: 20px 0;
    }
}