.history-page-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}


.history-page-wrapper .top-bar-top{
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid #DFDFE3;
  flex-shrink: 0;
  z-index: 5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.history-area-wrapper{
  overflow-y: scroll;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: white; */
  height: calc(100% - 85px);
  padding: 0 15%;
  flex-direction: column;
}

#history{
  display: flex;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
  background-color: white;
  width: calc(100% - 40px);
  text-decoration: none;
  color: #333;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

#history .history-invisible-button{
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
}

#history #historyWrapper{
  margin: 40px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100%;
} 

#history #history-logo-wrapper{
  height: 60px;
  width: 60px;
  border-radius: 15px;
  /* background-color: #dadadf; */
  border: #E6E6E6 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#history #history-company-logo{
  max-height: 40px;
  max-width: 40px;
}

#history #history-description{
  font-size: 0.8em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* number of lines to show */
          line-clamp: 6;
  -webkit-box-orient: vertical;
}

#history .history-button{
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  margin: 0 0 10px 10px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  width: 85px;
}

#history .edit{
  border: #8075FF solid 1px;
  color: #8075FF;
  text-decoration: none;
}


#history .edit:hover{
  border: #8075FF solid 1px;
  background-color: #8075FF;
  color: white;
}

#history .view{
  border: #8075FF solid 1px;
  color: #8075FF;
  text-decoration: none;
}

#history .view:hover{
  border: #8075FF solid 1px;
  background-color: #8075FF;
  color: white;
}

#history .delete{
  border: #8B88A7 solid 1px;
  color: #8B88A7;
}

#history .delete:hover{
  border: #8B88A7 solid 1px;
  background-color: #8B88A7;
  color: white;
}

#history #history-score{
  font-size: 0.9em;
  border-radius: 16px;
  height: 25px;
  width: auto;
  background-color: #E7E5FE;
  color: #8075FF;
  font-weight: 600;
  border: 0 none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60px;
}

#history #history-text{
  width: calc( 100% - 170px );
  justify-content: space-between;
}

#history #history-text-top-left{
  width: 100%;
}

#history #history-title{
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

#history #history-company{
  width: 100%;    
}

#history #history-company-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;  
  font-size: 0.9em;   
  margin-bottom: 8px;
}

.history-page-wrapper .top-bar-top-left{
  display: flex;
  justify-content: center;
  /* width: calc(100% - 210px); */
  flex: 1;
}

.history-page-wrapper .top-bar-search{
  display: flex;
  align-items: center;
  width: max(300px, 50%);
  /* margin-left: max(10px, 15%); */
  padding: 10px 0px 10px 0px;
  border: 1px solid;
  border-color: #DFDFE3;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  font-family: Helvetica;
  font-size: 1em;
  color: #A4A4A8;
  box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.5);
}

.history-page-wrapper .top-bar-search-input{
  border: none;
  width: 100%;
}

.history-page-wrapper .top-bar-search-input:focus {
  outline: none;
}

.history-page-wrapper .empty{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #d3d3d6;
  font-size: 1em;
}

.history-page-wrapper .empty-icon{
  height: 5em;
  margin-bottom: 5px;
  color: #DFDFE3;
}

#history .mobile-delete{
  display: none;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  /* font-size: 0.8em; */
  justify-content: center;
  align-items: center;
  /* padding: 6px 20px; */
  margin-left: 10px;
  /* border-radius: 5px; */
  cursor: pointer;
  /* border: #8B88A7 solid 1px; */
  color: #8B88A7;
  z-index: 1;
}

.history-area-inner-wrapper{
  width: 100%;
}

@media (max-width: 800px){
  .history-page-wrapper .top-bar-top{
      padding: 0;
      height: 50px;
  }

  .history-page-wrapper .job-page-account-circle{
      display: none;
  }

  .history-page-wrapper .top-bar-search{
      width: 100%;
  }

  .history-page-wrapper .top-bar-new-job{
      margin-right: 0;
      border: #8075FF 1px solid;
      background-color: transparent;
      color: #8075FF;
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
      width: 110px;
  }

  .history-page-wrapper .top-bar-top-left{
      height: 35px;
      margin: 0 10px;
  }

  .history-area-wrapper{
    padding: 0;
    height: calc(100% - 50px);
  }

  .history-page-wrapper #history{
    margin: 10px;
    width: calc(100% - 20px);
  }

  #history #historyWrapper{
    margin: 20px;
  } 

  #history .history-button {
    width: 70px;
  }

  #history #history-text{
    width: calc( 100% - 145px );
  }

  #history #history-logo-and-score{
    margin-right: 15px;
  }

  #history #history-logo-wrapper{
    width: 50px;
    height: 50px;
  }

  #history #history-company-logo{
    max-height: 30px;
    max-width: 30px;
  }

  #history #history-score{
    width: 50px;
  }

  #history .icons-bar{
    flex-direction: column;
  }
}

@media (max-width: 600px){
  #history .edit{
    display: none;
  }
}

@media (min-width: 600px){
  #history .view{
    display: none;
  }
}

@media (max-width: 400px){
  #history .history-buttons{
    display: none;
  }

  #history #history-text{
    width: calc( 100% - 65px );
  }

  /* #history .history-text-top-left-top-text{
    width: calc(100% - 50px);
  }

  #history .history-text-top-left-top{
    display: flex;
    width: 50px;
  } */

  #history #history-text-top-left {
    width: calc(100% - 30px);
  }

  #history .mobile-delete{
    display: flex;
  }


  #history .history-invisible-button{
    display: flex;
  }
}