

#selected-job{
    background-color: #F6F5FF;
}


.job-page-wrapper{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.job-page-wrapper .job-page-main{
    display: flex;
    height: calc(100% - 135px);
    width: 100%;
    position: relative;
}

.job-page-wrapper .job-page-left{
    height: 100%;
    width: 400px;
    flex-shrink: 0;
    overflow-y: scroll;
}

.job-page-wrapper .job-page-right-container{
    width: calc(100% - 400px);
    height: 100%;
    overflow-y: scroll;
    box-sizing: content-box;
    position: relative;
}

.job-page-wrapper .job-page-right{
    width: calc(100% - 15px);
    /* height: 100%; */
    padding: 2em;
    background-color: white;
    margin: 15px 15px 15px 0;
    overflow: hidden;
}

.job-page-wrapper .job-page-top-bar{
    width: 100%;
    height: 135px;
    background-color: white;
    flex-shrink: 0;
    z-index: 5;
}

.job-page-wrapper .top-bar-top{
    height: 85px;
    /* -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    /* background-color: #8075FF; */
    border-bottom: 1px solid #DFDFE3;
}

.job-page-wrapper .top-bar-status-bar{
    height: 50px;
    /* border-bottom: 1px solid #DFDFE3; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding-left: 15px; */
    /* background-color: #F6F5FF; */
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow-x: scroll;

    /* hide scroll bar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

/* hide scroll bar */
.job-page-wrapper .top-bar-status-bar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.job-page-wrapper .top-bar-status-bar-inner-wrapper{
    display: flex;
    align-items: center;    
}

.job{
    display: flex;
    margin: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
    background-color: white;
    /* height: 250px; */
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.job-inner-wrapper{
    margin: 20px;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
} 

.job-main{
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.job-title{
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.2em;
}

.job-company{
    font-size: 0.8em;
    margin-bottom: 0.2em;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.job-location{
    margin-bottom: 0.6em;
    color: #8B88A7;
    font-size: 0.8em;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.job-text-top{
    display: flex;
    width: 100%;
    margin-bottom: 5px;
}

#job-company-skeleton{
    width: 50%;
}

#job-location-skeleton{
    margin-bottom: 1.2em;
    width: 50%;
}

.job-status-bar{
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.job-status-dot{
    font-size: 0.4em;
    margin-right: 1em;
}

.job-page-wrapper .job-undefined{
    color: rgb(166, 173, 195);
}

.job-page-wrapper .job-saved{
    color: rgb(162, 179, 255);
}

.job-page-wrapper .job-applied{
    color: rgb(239, 162, 255);
}

.job-page-wrapper .job-interviewing{
    color: rgb(255, 187, 162);
}

.job-page-wrapper .job-offer{
    color: rgb(172, 226, 193);
}

.job-page-wrapper .job-rejected{
    color: rgb(166, 173, 195);
}

.job-status-text{
    color: #8B88A7;
    font-size: 0.7em;
    font-weight: 600;
}

.top-bar-status-button{
    height: 35px;
    width: auto;
    border: 1px solid #DFDFE3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    margin: 0 10px 0 0;
    cursor: pointer;
}

.top-bar-status-button-selected{
    background-color: #F6F5FF;
}

.top-bar-status-button-first{
    margin-left: 15px;
}

.job-status-button-text{
    color: #8B88A7;
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.job-page-job-type-count{
    font-weight: 400;
}

.job-status-button-dot{
    font-size: 0.5em;
    margin-right: 1em;
}

.job-page-right-top{
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
}

.job-page-right-top-title{
    font-size: 1.2em;
    margin: 0 10px 10px 0;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

#job-logo-wrapper{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    /* background-color: #dadadf; */
    border: #E6E6E6 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    flex-shrink: 0;
}

#job-company-logo{
    max-height: 1.8em;
    max-width: 1.8em;
}

.job-page-right-top-main{
    width: calc(100% - 90px);
    overflow-x: hidden;
    margin-right: 0.7em;
}

.job-logo-section{
    display: flex;
    height: 2.6em;
    margin-bottom: 0.8em;
    width: 100%;
}

.job-page-company{
    font-size: 0.8em;
    padding: 0 1em 0.3em 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.job-logo-section-text{
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    /* justify-content: space-between; */
}

.job-page-wrapper .icons-bar{
    display: flex;
}

.job-page-wrapper .job-page-icon-wrapper{
    display: flex;
    align-items: center;
    margin-right: 0.5em;
    font-size: 0.75em;
    color: #8B88A7;
}

.job-page-wrapper .job-icon{
    width: 1.1em;
    margin-right: 0.5em;
    flex-shrink: 0;
    color: #8B88A7;
}

.job-page-right-title{
    font-size: 1.1em;
    margin: 0.7em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.job-page-right-title-notes{
    font-size: 1.1em;
    margin: 0.7em 0;
    display: flex;
    align-items: center;
}

.job-page-right-title-timeline{
    font-size: 1.1em;
    margin: 0.4em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.job-page-right-desc{
    font-size: 0.9em;
    white-space: pre-line;
    /* white-space: pre-wrap; */
}

.status-select-wrapper{
    width: auto;
    display: inline-block;
}

.status-select{
    /* width: auto; */
    /* background-color: #DFDFE3; */
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 1em;
    /* box-shadow: inset 0px 0px 8px -2px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0px 0px 8px -2px rgba(0,0,0,0.3); */
    border: 1px solid #DFDFE3;
    font-size: 0.9em;
    /* display: inline-block; */

}

.status-select-button{
    height: 30px;
    width: auto;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    /* margin: 0 10px 0 0; */
    cursor: pointer;   
    background-color: none; 
}

.status-select-button-selected{
    background-color: white;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.68);
}

.job-page-more-icon{
    height: 1.6em;
    color: #8075FF;
    margin: 0 0.5em;
}

.job-page-title-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.job-action-button-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.job-page-wrapper .job-action-button{
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    padding: 0.4em 0;
    margin: 0 0 0.6em 0;
    border-radius: 5px;
    transition: 0.5s;
    cursor: pointer;
    border: #8075FF solid 1px;
    color: #8075FF;
    text-decoration: none;
}

.job-page-wrapper .job-action-button:hover{
    border: #8075FF solid 1px;
    background-color: #8075FF;
    color: white;
}

/* .job-page-wrapper .disabled{
    color: #A4A4A8;
    border: #A4A4A8 solid 1px;
    cursor: not-allowed;
}

.job-page-wrapper .disabled:hover{
    color: #A4A4A8;
    border: #A4A4A8 solid 1px;
    cursor: not-allowed;
    background-color: white;
} */

.job-page-wrapper .red{
    border: #F8B9B9 solid 1px;
    color: #F8B9B9;
    text-decoration: none;
}

.job-page-wrapper .red:hover{
    border: #F8B9B9 solid 1px;
    background-color: #F8B9B9;
    color: white;
}

.job-page-right-timeline{
    width: 300px;
    flex-shrink: 0;
}

.job-page-right-main{
    display: flex;
    width: 100%;
}

.event{
    display: flex;
    cursor: pointer;
    border-radius: 10px;
}

.event-left{
    width: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-top: 7px;
    flex-shrink: 0;
}

.event-left-circle{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    /* background-color: #8075FF; */
    flex-shrink: 0;
    border: #8075FF solid 3px;
}

.event-left-circle-upcoming{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    /* background-color: #8075FF; */
    flex-shrink: 0;
    border: #8B88A7 solid 3px;
}

.event-line{
    width: 1px;
    height: 100%;
    background-color: #8075FF;
    position: relative;
    margin-top: 7px;
}

.event-line-upcoming{
    width: 1px;
    height: 100%;
    background-color: #8B88A7;
    position: relative;
    margin-top: 7px;
}

.event-title{
    font-size: 0.9em;
    margin: 0.3em 0 0.2em 0;
}

.event-date{
    font-size: 0.8em;
    color: #8B88A7;
}

.event-description{
    font-size: 0.8em;
    color: #8B88A7;
    margin: 7px 0;
}

.job-page-right-left{
    padding-right: 10px;
    width: calc(100% - 300px);
    /* overflow-x: hidden; */
}

.job-page-right-add-event{
    height: 34px;
    padding: 0 10px;
    border: #A4A4A8 solid 1px;
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 17px;
    cursor: pointer;
    color: #A4A4A8;
}

.add-event-plus{
    height: 14px;
    padding-right: 6px;
}

.event:hover{
    background-color: #F6F5FF;
}

.event-right-top{
    display: flex;
    align-items: center;
}

.event-complete{
    font-size: 0.7em;
    color: #8075FF;
    /* margin: 0.3em 0 0.2em 0; */
    height: 1.4em;
    margin: 0.2em 1em 0 1em;
    padding: 0 1em;
    border-radius: 0.6em;
    border: #8075FF solid 1px;
}

.event-incomplete{
    border: #F8B9B9 solid 1px;
    color: #F8B9B9;
}

.delete-event-button{
    width: 100%;
    padding: 0.7em 0;
    font-size: 0.9em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: #F8B9B9 solid 1px;
    color: #F8B9B9;
    font-weight: 600;
    transition: 0.5s;
    cursor: pointer;
}

.delete-event-button:hover{
    color: white;
    background-color: #F8B9B9;
}

.delete-event-button-disabled{
    color: #A4A4A8;
    border: #A4A4A8 solid 1px;
    cursor: not-allowed;
}

.delete-event-button-disabled:hover{
    color: #A4A4A8;
    border: #A4A4A8 solid 1px;
    cursor: not-allowed;
    background-color: white;
}

.input-modal-cancel-disabled{
    cursor: not-allowed;
}

.top-bar-top-right{
    display: flex;
    align-items: center;
    /* width: 210px; */
    justify-content: flex-end;
}

.top-bar-search{
    display: flex;
    align-items: center;
    width: max(300px, 50%);
    /* margin-left: max(10px, 15%); */
    padding: 10px 0px 10px 0px;
    border: 1px solid;
    border-color: #DFDFE3;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 1em;
    color: #A4A4A8;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.5);
}

.top-bar-search-input{
    border: none;
    width: 100%;
}

.top-bar-search-input:focus {
    outline: none;
}

.job-page-account-circle{
    background-color: #C5BEFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    /* margin-right: 20px; */
    text-align: center;
    flex-shrink: 0;
    border: none;
    text-decoration: none;
}
  
.job-page-account-text{
    font-size: 20px;
    color: #8075FF;
    font-weight: 600;
}

.top-bar-new-job{
    border-radius: 16px;
    height: 35px;
    width: auto;
    /* background-color: #8075FF; */
    background-color: white;
    /* color: #ffffff; */
    color:#8075FF;
    /* border: 0 none; */
    border: 1px solid #8075FF;
    padding: 10px 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    /* font-weight: 600; */
    cursor: pointer;
    flex-shrink: 0;
    text-decoration: none;
    transition: 0.5s;
}

.top-bar-new-job:hover{
    opacity: 70%;
  }

.top-bar-search-icon{
    width: 15px;
    color: #A4A4A8;
    margin: 0 5px 0 10px;
}

.add-job-plus{
    height: 16px;
    padding-right: 0.4em;
}

.top-bar-top-left{
    display: flex;
    justify-content: center;
    /* width: calc(100% - 210px); */
    flex: 1;
}

.job-page-wrapper .event-right{
    width: calc(100% - 50px);
    overflow: hidden;
}

.job-page-right-top-status{
    display: none;
    align-items: center;
    margin-bottom: 0.5em;    
}

.job-page-right-top-status-text{
    color: #8B88A7;
    font-size: 0.8em;
    font-weight: 600;
}

.job-page-right-top-status-dot{
    font-size: 0.5em;
    margin-right: 1em;
}

.job-page-wrapper .empty-message{
    width: 100%;
    height: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 1em;
}

.job-page-right-top-buttons{
    height: 9em;
}

.job-page-right-mobile-top{
    display: none;
    height: 25px;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.job-page-right-mobile-top-status{
    display: none;
    align-items: center;
}

.job-page-close-job-desc{
    width: 25px;
    color: #8B88A7;
    cursor: pointer;
}

.job-page-wrapper .empty{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 1em;
}

.job-page-wrapper .empty-icon{
    height: 4em;
    margin-bottom: 5px;
    color: #DFDFE3;
}

@media (max-width: 1200px){
    .status-select-wrapper{
        display: none;
    }

    .job-page-right-top-status{
        display: flex;
    }
}

@media (max-width: 1100px){
    .job-page-right-main{
        flex-direction: column;
    }

    .job-page-right-left{
        width: 100%;
        padding: 0;
    }

    .job-page-right-timeline{
        width: 100%;
    }
}

@media (max-width: 800px){
    .job-page-wrapper .top-bar-top{
        padding: 0;
        height: 50px;
    }
    
    .job-page-wrapper .top-bar-status-bar{
        height: 40px;
        /* padding-left: 10px; */
    }
    
    .job-page-wrapper .job-page-top-bar{
        height: 90px;
    }

    .job-page-wrapper .job-page-account-circle{
        display: none;
    }

    .top-bar-status-button{
        height: 30px;
    }

    .top-bar-status-button-first {
        margin-left: 10px;
    }

    .job-page-wrapper .top-bar-search{
        width: 100%;
    }

    .job-page-wrapper .top-bar-new-job{
        margin-right: 0;
        border: #8075FF 1px solid;
        background-color: transparent;
        color: #8075FF;
        padding: 10px;
        border-radius: 5px;
        margin-right: 10px;
        width: 100px;
    }

    .job-page-wrapper .top-bar-top-left{
        height: 35px;
        margin: 0 10px;
    }

    .job-page-wrapper .job-page-main{
        height: 100%;
        position: absolute;
    }

    .job-page-wrapper .job-page-left{
        height: calc(100% - 90px);
        margin-top: 90px;
    }

    .job-page-wrapper #selected-job{
        background-color: white;
    }

    .job-page-wrapper .expand-job-page-right-container{
        width: 100%;
    }

    .job-page-wrapper .expand-job-page-left{
        width: 100%;
    }

    .job-page-wrapper .job-page-right{
        margin: 0;
        padding: 20px;
    }

    .job-page-wrapper .job-action-button{
        width: 70px;
    }

    .job-page-wrapper .expand-job-page-right{
        width: calc(100% - 20px);
        margin: 10px;
    }

    .job-page-wrapper .hide{
        display: none;
    }

    .job-page-right-top-main{
        width: calc(100% - 70px);
    }

    .job-page-right-mobile-top{
        display: flex;
    }

    .job-page-right-top-status{
        display: none;
    }

    .job-page-right-mobile-top-status{
        display: flex;
    }

    .templates-page-wrapper .job-page-account-circle{
        display: none;
    }

    .templates-page-wrapper .top-bar-top-left{
        height: 35px;
        margin: 0 10px;
    }

    .templates-page-wrapper .top-bar-search{
        width: 100%;
    }

    .job-page-wrapper .job{
        margin: 10px;
    }

}

@media (max-width: 400px ){
    .job-page-wrapper{
        font-size: 16px;
    } 
    
    .job-page-wrapper .icons-bar{
        flex-direction: column;
    }

    .job-page-wrapper .job-page-icon-wrapper{
        margin-top: 5px;
    }
}

@media (max-width: 600px){
    .desktop-only{
      display: flex;
    }
}