.membership-form {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 8%;
    /* padding: 0 5vw 50px 10vw; */
    overflow: hidden;
}

.membership-points-form{
    width: 430px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    /* align-self avoids stretching to height of offers container */
    align-self: flex-start; 
    margin-bottom: 40px;
}

.membership-banner{
    width: 100%;
    height: 600px;
    background: linear-gradient(to bottom, rgba(224, 114, 234, 0.3) 10%, rgba(128, 117, 255, 0.3) 80%, rgba(246, 245, 248, 1) 100%);
    display: flex;
    overflow: hidden;
}

.offers-container{
    width: 100%;
    margin: 25px 0;
    display: flex;
    justify-content: center;
}

.offers-inner-container{
    grid-gap: 10px;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(270px,1fr));
    justify-items: center;
    width: 100%;
}

.rewards-container{
    width: 100%;
    margin: 25px 0;
    display: flex;
    justify-content: center;
}

.rewards-inner-container{
    grid-gap: 10px;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(270px,1fr));
    justify-items: center;
    width: 100%;
}

.membership-points-bar {
    width: 100%;
    height: 12px;
    border: solid 1.5px rgb(230, 230, 230);
    margin-top: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

div.membership-points-bar div {
    width: 33.3%;
    height: 9px;
    background: radial-gradient(circle at left, #FBD0FF, #BAB4FF);
    /* opacity: 0.6; */
    border-radius: 4.5px;
}

/* .membership-points-bar-premium{
    background-color: #eaaef3; 
} */

/* div.membership-points-bar-premium div {
    background: white;
    opacity: 0.6;
} */

.membership-points-number{
    text-align: left;
    font-size: 30px;
    color: #8075FF;
}

.membership-points-target{
    text-align: right;
    font-size: 0.8em;
    color: #8B88A7;
}

.membership-points-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
}

.membership-banner-text-wrapper{
    padding: 80px 0 0 150px;
}


.membership-banner-text{
    font-size: 125px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: white;
    line-height: 120px;
    display: flex;
    align-items: center;
}

.membership-banner-image{
    position: relative;
}

.membership-banner-star{
    margin-left: 30px;
    padding-top: 10px;
}
  
.membership-banner-card-top{
    height: 200px;
    width: 370px;
    background: linear-gradient(150deg, #fac1ff, #b0a9ff);
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: #f3dbff solid 1px;
    border-bottom: #dedbff solid 1px;
    transform: skew(10deg) rotate(-10deg);
    flex-shrink: 0;
    top: 70px;
    left: 70px;
}

.membership-banner-card-bottom{
    height: 200px;
    width: 370px;
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #ebe9ff solid 1px;
    border-right: #ebe9ff solid 1px;
    transform: skew(-5deg) rotate(10deg);
    backdrop-filter: blur(10px);
    flex-shrink: 0;
    top: 255px;
    left: -15px;
    background: linear-gradient(-20deg,  rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.05) 30%, rgba(255, 255, 255, 0.05) 60%, rgba(255, 255, 255, 0.6) 100%);

}

.membership-company-logo{
    height: 50px;
    color: #ffffff;
}

.membership-coin{
    position: absolute;
    top: 300px;
    left: 310px;
}

.membership-standard-member-icon{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    opacity: 1;
}

.membership-points-info{
    height: 170px;
    /* background: linear-gradient(160deg,  #d0c9fc, #9187d6); */
    border-radius: 10px 10px 0 0;
    padding: 30px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    /* color: white; */
    justify-content: space-between;
}

.membership-points-info-premium{
    background: linear-gradient(160deg,  #fcd8ff, #c777cd);
}



.membership-points-top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.membership-points-top-left{
    display: flex;
    align-items: flex-start;
    height: 100%;
    max-width: calc(100% - 100px);
}


.membership-member{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    max-width: calc(100% - 75px);
}

.membership-member-name{
    font-size: 1.2em;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.membership-member-status{
    font-size: 0.9em;
    /* color: #8B88A7; */
}

.membership-premium-expire{
    font-size: 0.8em;
    color: #8B88A7;
}

.membership-points-count{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: right;
    align-items: flex-end;
    /* margin-top: 5px;     */
    max-width: 100px;
}

.membership-points-count-number{
    font-size: 1.2em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: right;
}

.membership-points-count-star{
    height: 0.9em;
    width: auto;
    margin-right: 7px;
    color: #C5BEFF;
}


.membership-points-count-text{
    font-size: 0.9em;
    /* color: #8B88A7; */
}

.membership-points-count .history-button{
    font-size: 0.8em;
    color: #8B88A7;
    cursor: pointer;
    transition: color .4s;
    text-decoration: underline;
}

.membership-points-count .history-button-premium{
    color: #fcd8ff;
}

.membership-points-count .history-button:hover{
    opacity: 70%;
}


.membership-points-benefits{
    padding: 30px 0;
}

.membership-points-benefits .items-container{
    color: #8B88A7;
    display: flex;
    /* justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap; */
    /* justify-content: center; */
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
}

.membership-points-benefits .premium-items-container{
    color: #a688a7;
}

.membership-points-benefits-title{
    font-size: 1em;
    margin-bottom: 20px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.membership-points-benefits-item{
    /* padding: 12px 0 12px 40px; */
    margin: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* width: calc(50% - 40px); */
    padding: 12px 20px;
}

.items-container .benefits-icon{
    height: 35px;
    width: 35px;
    color: #C5BEFF;
    /* color: #8075FF; */

}

.premium-items-container .benefits-icon{
    color: #f0c3f0;
}


.membership-points-benefits-text{
    font-size: 0.8em;
    /* font-weight: 600; */
    /* margin-top: 10px; */
    padding-left: 20px;
}

.membership-right-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* width: 100%;
    height: 100%; */
    margin-left: 30px;
    /* max-width: 550px; */
    flex: 1;
    max-width: 550px;
}

.membership-page-bar{
    display: flex;
    justify-content: center;
}

.membership-page-button{
    border-radius: 0;
    background-color: transparent;
    color: #9085a5;
    font-size: 0.9em;
    width: 100px;
    margin: 5px 10px;
    transition: color 0.5s;

}

.membership-page-button:hover{
    color: #8075FF;
}



.membership-page-selected{
    color: #8075FF;
    box-shadow: inset 0 -2px 0 0 #8075FF;
}

.membership-form .dashboard-membership-icon{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: radial-gradient(circle at bottom, #FBD0FF, #BAB4FF);
    font-size: 40px;
    font-weight: 800;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    color: #e0b9ff;
    /* margin-top: 10px; */
    /* text-shadow: 3px 3px 0 white; */
    text-decoration: none;
    flex-shrink: none;
}

.membership-form .premium{
    background: radial-gradient(circle at bottom right, #A9DBFF, #FEBCFF);
    color: #d1b9ff;
}

.membership-form .benefits-title-right{
    font-size: 0.8em;
    color: #8B88A7;
    cursor: pointer;
    text-decoration: underline;
    transition: color .4s;
}

.membership-form .benefits-title-right:hover{
    opacity: 70%;
}

@media (max-width: 1300px ){

    .membership-form {
        padding: 10px 5%;
    }
    
}

@media (max-width: 1100px ){
    .membership-banner-image{
        display: none;
    }

    .membership-banner-text-wrapper{
        padding-left: 8%;
    }
    
}

@media (max-width: 800px ){
    .membership-banner{
        display: none;
    }

    .membership-form{
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }

    .membership-points-form{
        width: 100%;
        margin-bottom: 20px;
    }

    .membership-right-container{
        margin: 0;
        max-width: none;
        width: 100%;
    }
    
}

@media (max-width: 500px){
    .offers-container{
        margin: 25px 0 0 0;
    }    
    .rewards-container{
        margin: 25px 0 0 0;
    }    
}

@media (max-width: 400px){
    .membership-points-info{
        padding: 30px 30px 15px 30px;
    }

    .items-container .benefits-icon{
        width: 30px;
        height: 30px;
    }

    .membership-points-info{
        padding: 20px 20px 10px 20px;
    }

    .membership-points-benefits-title{
        padding: 0 20px;
    }

    .membership-points-benefits .items-container{
        padding: 0 20px;
    }

    .membership-points-benefits-item{
        margin: 10px 0;
    }

    .membership-form .dashboard-membership-icon{
        width: 50px;
        height: 50px;
        font-size: 30px;
    }

    .rewards-inner-container{
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    }

    .offers-inner-container{
        display: flex;
        flex-direction: column;
    }

    .membership-member{
        max-width: calc(100% - 65px);
    }
    
}