@keyframes fly {
    100% {
        transform:rotate(1turn) translate(100px) rotate(-1turn);
    }
}

@keyframes flyPlus {
    100% {
        transform:rotate(-1turn) translate(100px) rotate(1turn);
    }
}

@keyframes flyMove {
    100% {
        transform:rotate(-1turn) translate(-100px) rotate(1turn);
    }
}

.index-banner{
    width: 100%;
    height: 760px;
    position: relative;
    overflow-anchor: none;
    overflow: hidden;
}
  
.index-background {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #d05fb7, #8075FF);
    position: relative;
    overflow:hidden;
    opacity: 0.7;
}

.index-background-curve{
    position: absolute;
    top: 180px;
    left: 0px;
    background: linear-gradient(to bottom, #ffdaf8, #f8f8ff, #ffffff, #ffffff);
    /* background-color: white; */
    width: max(2000px, 200%);
    display:block;
    height: 580px;
    bottom: -160px;
    left: -10%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    /* overflow: hidden; */
}

.index-background-plus{
    background-image: url('../images/plus_pattern.svg');
    /* background-position: left 10%; */
}

.aurora-base {
    position: absolute;
    filter: blur(80px);
    opacity: 0.7;
}

.aurora-one {
    border-radius: 100%;
    width: 400px;
    height: 600px;
    background-color: #6355ff;
    left:200px;
    top:-300px;
    /* animation: fly 3s linear infinite; */
    transform:rotate(0) translate(100px) rotate(0);
}

.aurora-two {
    width: 500px;
    height: 600px;
    background-color: #03ddff;
    top: 0px;
    left:-50px;
    border-radius: 100%;
}

.aurora-three {
    border-radius: 100%;
    width: 450px;
    height: 450px;
    top:-80px;
    left:1000px;
    background-color: rgb(229, 48, 246);
    /* animation: flyPlus 2s linear infinite; */
    transform:rotate(0) translate(100px) rotate(0);
}

.aurora-four {
    border-radius: 100%;
    width: 500px;
    height: 500px;
    background-color: #7C336C;
    top:50px;
    left:580px;
    transform:rotate(45deg);
}

.aurora-five {
    border-radius: 100%;
    width: 500px;
    height: 300px;
    top:60px;
    left:650px;
    background-color: rgb(35, 174, 255);
    /* animation: flyMove 6s linear infinite; */
    transform:rotate(0) translate(-100px) rotate(0);
}

.index-main{
    background-color: white;
    width: 100%;
}

.index-gradient-font{
    background: linear-gradient(to top right, #A097FF , #f58aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.index-gradient-font-reverse{
    background: linear-gradient(to bottom left, #A097FF , #f58aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.index-banner-content-wrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 800px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.index-banner-content{
    width: 80%;
    margin-top: calc(3vw + 66px);
    display: flex;
}

.index-banner-left{
    flex: 4;
    padding-top: 100px;
}

.index-banner-right{
    flex: 5;
    position: relative;
}

.index-banner-title{
    font-size: max(30px, 4vw);
    font-weight: 600;
    color: rgb(90, 77, 105);
    margin-bottom: 20px;
    line-height: 0.9;
    width: max(200px, 32vw);
    /* font-family: 'Plus Jakarta Sans', sans-serif; */
}

.index-banner-text{
    font-size: max(22px, 1.6vw);
    font-weight: 600;
    color: rgb(90, 77, 105);
    width: 90%;
}

.index-banner-button-container{
    display: flex;
    margin-top: 30px;
}

.index-banner-button{
    border-radius: 20px;
    height: 40px;
    width: auto;
    background-image: linear-gradient(to right, #aca4ff , #f698ff);
    color: #ffffff;
    font-weight: 600;
    border: 0 none;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    white-space: nowrap;
    text-decoration: none;
}

.index-banner-demo{
    background-color: transparent;
    background-image: none;
    color: rgb(90, 77, 105);
}

.index-banner-box{
    display: flex;
    padding: 18px;
    border-radius: 10px;
    box-shadow: -15px 15px 40px -5px rgba(106, 88, 128, 0.3);
    -webkit-box-shadow: -15px 15px 40px -5px rgba(106, 88, 128, 0.3);
    background-color: rgba(255, 255, 255, 0.25);
    text-decoration: none;
    color: #333;
    position: absolute;
    backdrop-filter: blur(20px);
}

.index-banner-box-interview{
    top: 40px;
    left: 130px;
    width: 150px;
    height: 150px;
    flex-direction: column;
    text-align: center;
}

.index-banner-interview-name{
    color: rgb(90, 77, 105);
    font-size: 16px;
    font-weight: 600;
}

.index-banner-interview-resumes{
    color: rgb(90, 77, 105);
    font-size: 11px;
    margin-top: 27px;
}

.index-banner-interview-stat{
    font-size: 28px;
    font-weight: 700;
    margin-top: 5px;
}

.index-banner-box-score{
    top: -5px;
    left: 300px;
    width: 185px;
    height: 235px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.index-banner-box-section{
    top: 250px;
    left: 320px;
    width: 300px;
    height: 80px;
    padding: 10px 18px 10px 18px;
}

.index-banner-box-job{
    top: 350px;
    left: 280px;
    width: 250px;
    height: 118px;
}

.index-banner-box-tracker{
    top: 210px;
    left: 60px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.circle-progress-index-page{
    fill: none;
    stroke: #8075FF;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.circle-text-index-page{
    position: absolute;    
    font-size: 30px;
    font-weight: 600;
}

.score-container-index-page{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}

.circle-background-index-page{
    fill: none;
    stroke: rgba(230, 225, 236, 0.5);
}

.scan-bar-title-index-page{
    color: rgb(90, 77, 105);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 17px;
}

.scan-bar-comment-index-page{
    color: rgb(90, 77, 105);
    font-size: 12px;
}

.banner-box-background{
    border-radius: 100%;
    width: 300px;
    height: 500px;
    background-color: rgba(187, 0, 255, 0.2);
    top:30px;
    left:150px;
    transform:rotate(45deg);
}

.section-button-index-page{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Helvetica;
    border: 1.5px solid transparent;
}

/* PROGRESS BAR */
.score-bar-index-page {
    width: 100%;
    height: 5px;
    background-color: rgba(230, 225, 236, 0.5);
    margin: 5px 0 3px 0;
    border-radius: 5px;
}

div.score-bar-index-page div {
    width: 33.3%;
    height: 5px;
    background: linear-gradient(to right, #aca4ff , #f698ff);
    border-radius: 5px;
}

.section-middle-index-page{
    display: flex;
    flex-direction: column;
    margin: 0px 5px 0px 15px;
    width: 100%;
}

.section-score-circle-index-page{
    background-color: #E7E5FE;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-shrink: 0;
}

.section-score-index-page{
    font-size: 20px;
    font-weight: 600;
    color: #8075FF
}

.section-text-title-index-page{
    font-size: 14px; 
    color: rgb(90, 77, 105);
    font-weight: 600;
    text-align: left;
    margin-bottom: 2px;
}
  
.section-text-desc-index-page{
    font-size: 12px; 
    color: rgb(90, 77, 105);
    text-align: left;
    margin-bottom: 3px;
}

.history-logo-wrapper-index-page{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: linear-gradient(to top right, #c9c4ff , #f9bbff);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.history-company-logo-index-page{
    max-height: 35px;
    max-width: 35px;
}
  
.history-logo-and-score-index-page{
    margin-right: 15px;
}

.history-score-index-page{
    border-radius: 16px;
    height: 23px;
    width: 50px;
    background-color: #E7E5FE;
    color: #8075FF;
    font-weight: 700;
    border: 0 none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
}

.history-title-index-page{
    font-size: 15px;
    font-weight: 600;    
    color: rgb(90, 77, 105);
}

.history-company-index-page{
    font-size: 14px;
    color: rgb(90, 77, 105);
    margin-bottom: 5px;
}

.history-text-index-page{
    font-size: 12px;
    color: rgb(90, 77, 105);
    margin-bottom: 5px;
}

.index-banner-box-tracker-title{
    font-size: 13px;
    color: rgb(90, 77, 105);
    margin-bottom: 10px;    
}

/* PROGRESS BAR */
.index-banner-box-tracker-score {
    width: 5px;
    height: 100%;
    background-color: rgba(230, 225, 236, 0.5);
    margin: 5px 0 3px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 10px;
}

div.index-banner-box-tracker-score div {
    width: 5px;
    height: 33.3%;
    background: linear-gradient(to top, #aca4ff , #f698ff);
    border-radius: 5px;
}

.index-banner-box-tracker-bar-wrapper{
    height: 100%;
    display: flex;
}

.index-banner-box-tracker-bar{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.index-banner-box-tracker-month{
    color: rgb(161, 157, 169);
    font-size: 11px;
    margin-top: 5px;
}

.index-section{
    width: 100%;
    display: flex;
    justify-content: center;
    /* text-align: center; */
    align-items: center;
    color: rgb(90, 77, 105);
    /* padding: 2em 0; */
    display: flex;
    flex-direction: column;
    height: auto;
    cursor: default;
}

.index-section-title{
    font-weight: 600;
    font-size: 1.8em;
    white-space: nowrap;
    text-align: center;
    /* display: flex;
    justify-content: center;
    flex-shrink: 0; */
    z-index: 1;
    background: linear-gradient(to top right, #A097FF , #f58aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 100px;
}

.index-how-it-works{
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
}

.index-how-it-works .step{
    width: 15em;
    display: flex;
    justify-content: flex-start;
    margin: 2em 1.5em;
    padding: 2em;
    /* background-color: #4dccd0; */
    text-align: center;
    flex-direction: column;
    border-radius: 0.5em;
    box-shadow: -0.5em 0.5em 3em -0.3em rgba(106, 88, 128, 0.3);
    -webkit-box-shadow: -0.5em 0.5em 3em -0.3em rgba(106, 88, 128, 0.3);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
}

.index-how-it-works .step-image{
    width: 2.5em;
    height: 2.5em;
    position: relative;
    background-color: #E7E5FE;
    color: #8075FF;
    border-radius: 50%;
    padding: 0.5em;
    margin-bottom: 1em;
}

.index-section .explain{
    font-weight: 600;
    font-size: 1em;
    margin: 1em 0;
    width: 50%;
    text-align: center;
    z-index: 1;
}

.index-how-it-works .step-title{
    font-weight: 600;
    margin-bottom: 1em;
    font-size: 1.1em;
    text-align: left;
}

.index-how-it-works .step-text{
    font-size: 0.9em;
    text-align: left;
}

.index-section-feature{
    display: flex;
    padding: 40px 0;
    position: relative;
    width: 100%;
}

.index-section-feature-reverse::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #A097FF;
    background: linear-gradient(to top right, #A097FF , #f58aff);
    transform: skewY(-4deg);
}

/* .feature-top{
    display: flex;
    margin: 0 0 30px 0;
} */

.index-page-feature-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.index-page-feature-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.index-page-feature-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20%;
}

.index-page-feature-image{
    width: 90%;
    z-index: 1;
}

.index-section-feature-title{
    font-weight: 600;
    font-size: 1.8em;
    text-align: left;
    /* display: flex;
    justify-content: center;
    flex-shrink: 0; */
    z-index: 1;
    background: linear-gradient(to top right, #A097FF , #f58aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
    padding: 0.5em 0;
}

.feature-title-white{
    color: white;
    -webkit-text-fill-color: white
}

.feature-explain-white{
    color: white;
}

.index-section-feature-explain{
    font-size: 1 em;
    text-align: left;
    z-index: 1;
}

.index-rights{
    display: flex;
    justify-content: center;
    text-align: center;
    color: #8c8c8c;
    font-size: 0.8em;
}

.index-support{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;   
    cursor: pointer; 
    margin-bottom: 0.5em;
}

.footer-section{
    background-color: #8075FF;
    color: #c9c4ff;
    padding: 4em 10%;
    margin-top: 6em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.footer-section .footer-left{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footer-section .logo-wrapper{
    display: flex;
    width: 130px;
    justify-content: left;
}

.footer-section .logo-white{
    width: 130px;
    color: #c9c4ff;
    margin-top: 0;
}

.footer-section .socials{
    margin-top: 1em;
}

.footer-section .social{
    width: 2em;
    cursor: pointer;
    transition: 0.5s;
    margin-right: 0.6em;
}

.footer-section .social:hover {
    color:white;
}

.footer-section .slogan{
    margin: 0.5em 0;
    font-size: 0.9em;
}

.footer-section .copyright{
    margin: 1.5em 0;
    font-size: 0.7em;    
}

.footer-section .menu{
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 2em;
}

.footer-section .menu-title{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.footer-section .menu-text{
    font-size: 0.9em;
    text-decoration: none;
    color: #c9c4ff;
    padding: 0.2em 0;
    transition: 0.5s;
    cursor: pointer;
}

.footer-section .menu-text:hover {
    color:white;
}

.index-video{
    margin-top: 40px;
    width: 50%;
    min-width: 280px;
    height: 28.125vw;
    min-height: 157.5px;
}

/* .feature-highlight{
    margin-top: 100px;
} */

@media (max-width: 1100px){
    .index-background-curve{
        top: 140px;
        height: 720px;
    }
}

@media (max-width: 800px ){
    /* .index-background{
        display: none;
    } */

    .index-background-curve{
        display: none;
    }

    .index-banner{
        margin-bottom: 50px;
    }

    .index-banner-right{
        display: none;
    }
    
    .index-banner-left{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .index-banner-title{
        color: white;
        font-size: max(10vw, 40px);
        width: 100%;
    }

    .index-gradient-font{   
        color: white;
        -webkit-text-fill-color: white;
    }

    .index-banner-text{
        color: white;
        font-size: max(3vw, 17px);
        margin-top: 1vw;
    }

    .index-banner-demo{
        color: white;
    }

    .index-banner-button{
        font-size: 1em;
        /* height: 30px; */
        /* padding: 0 15px; */
        background: white;
        color: #8075FF;
        margin-bottom: 15px;
    }

    .index-banner-demo{
        background: none;
        color: white;
        font-size: 0.9em;
    }

    .index-banner-button-container{
        flex-direction: column;
    }

    .index-section .explain{
        width: 70%;
    }

    .footer-section{
        flex-direction: column;
    }

    .footer-main .menu{
        align-items: left;
        margin-left: 0;
        text-align: left;
    }

    .footer-main{
        margin-top: 40px;
    }

    .index-video{
        width: 60%;
        height: 33.75vw;
    }

    .aurora-base{
        display: none;
    }

    .index-background{
        /* background: radial-gradient(to right, #d05fb7, #8075FF); */
        background: radial-gradient(
            ellipse 120% 150% at top left,
            #03ddff,
            #8075FF,
            #d05fb7
        );
    }

    .index-section-feature{
        flex-direction: column-reverse;
    }

    .index-section-feature-reverse{
        flex-direction: column;
    }

    .index-page-feature-left{
        align-items: center;
        width: 100%;
    }

    .index-page-feature-right{
        align-items: center;
        width: 100%;
    }

    .index-page-feature-text{
        padding: 10%;
        text-align: center;
        align-items: center;
    }

    .index-section-feature-explain{
        text-align: center;
    }

    .index-section-feature-title{
        text-align: center;
    }
}