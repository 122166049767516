.dashboard{
    /* font-size: 18px; */
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.dashboard .main{
    padding: 20px;
    width: calc(100% - 270px);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.dashboard .main-inner{
    height: 100%;
}

.dashboard .title{
    margin-left: 10px;
    font-size: 1.5em;
    height: 32px;
}

.dashboard .stats{
    display: flex;
    flex-direction: row;
}

.stat-box{
    flex: 1;
    /* min-width: 150px; */
    height: 200px;
    margin: 20px 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3);
    /* -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3); */
    flex-shrink: 0;
    text-decoration: none;
    color: rgb(90, 77, 105);
    background-color: white;
}

.stat-box .stat-number{
    margin-bottom: 12px;
    display: flex;
    align-items: baseline;
}

.stat-box .stat-used{
    font-size: 25px;
    font-weight: 600;
    color: rgb(90, 77, 105);
}

.stat-box .stat-limit{
    color: #8B88A7;
    font-size: 16px;
    display: flex;
    margin-left: 3px;
}

.stat-box .stat-icon{
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 50%;
    background-color: #E7E5FE;
    color: #8075FF;
}

.stat-box .stat-desc{
    font-size: 0.9em;
    margin-bottom: 10px;
    height: 35px;
}

.dashboard .bar {
    width: 100%;
    height: 8px;
    background-color: rgb(230, 230, 230);
    margin-top: 5px;
    border-radius: 5px;
}
  
.dashboard div.bar div {
    width: 33.3%;
    height: 8px;
    background-color: #8075FF;
    border-radius: 5px;
}

.dashboard .right-bar{
    height: 100%;
    width: 270px;
    background-color: white;
    flex-shrink: 0;
    right: 0;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: fixed; */
}

.dashboard .middle{
    display: flex;
    width: 100%;
    height: calc( 100% - 272px );
    /* height: 100%; */
}

.dashboard .histories{
    /* background-color: #8B88A7; */
    margin: 0 10px;
    /* padding-bottom: 20px; */
    flex: 6;
    overflow: hidden;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3);
    padding-top: 20px;
    border-radius: 10px;
    min-height: 200px;
}

.dashboard .histories-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 10px;
    height: 22px;
    padding: 0 20px;
}

.dashboard .histories-main{
    overflow: scroll;
    height: calc(100% - 32px);
}

.dashboard .histories-more{
    font-size: 0.9em;
    color: #8B88A7;
}

.dashboard .templates{
    /* background-color: #8B88A7; */
    overflow: hidden;
    margin: 0 10px;
    flex: 4;
    background-color: white;
    border-radius: 10px;
    padding-top: 20px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3);
    min-height: 200px;
}

.dashboard .templates-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 10px;
    height: 22px;
    padding: 0 20px;
}

.dashboard .templates-more{
    font-size: 0.9em;
    color: #8B88A7;
}

.dashboard .templates-main{
    height: calc(100% - 32px);
    /* overflow: scroll;
    height: calc(100% - 32px);
    /* box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3); */
}

.dashboard .template{
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-top: 1px solid rgb(212, 204, 221);
    color:rgb(90, 77, 105);
    text-decoration: none;
    font-size: 15px;
}

.dashboard .template:last-child{
    border-bottom: none;
}

.dashboard .template-created{
    padding-bottom: 10px;
}

.dashboard .account-circle{
    background-color: #C5BEFF;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px 0 10px 0;
    text-align: center;
    flex-shrink: 0;
    text-decoration: none;
}

.dashboard .account-text{
    font-size: 30px;
    color: #8075FF;
    font-weight: 600;
}


.dashboard .dashboard-profile-info{
    height: 200px;
    border-radius: 10px 10px 0 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0 20px;
}

.dashboard .dashboard-member-name{
    font-size: 1em;
    font-weight: 600;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard .dashboard-member-status{
    font-size: 0.8em;
    color: #8B88A7;
    margin-top: 0.3em;
}

.dashboard .dashboard-points-count-text{
    font-size: 15px;
}

.dashboard .dashboard-points-count-number{
    font-size: 20px;
    font-weight: 600;    
}

.dashboard .messages{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    font-size: 0.9em;
    font-weight: 600;
    height: calc(100% - 200px);
    overflow: scroll;
}

.dashboard .messages-inner-container{
    font-size: 16px;
    margin-top: 10px;
}

.dashboard .file{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    flex-shrink: 0;
    text-decoration: none;
    color:rgb(90, 77, 105);
    border-bottom: 1px solid #DFDFE3;
    padding: 0 20px;
}

.dashboard .file:hover{
    background-color: #F6F5FF;
}

.dashboard .template-title{
    width: calc(100% - 130px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
}

.dashboard .template-title-key{
    width: calc(100% - 95px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
}

.dashboard .template-update-time{
    width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard .selected-file{
    background-color: #F6F5FF;
    /* border: 1.5px solid #c1bee4; */
    box-shadow:inset 0px 0px 0px 1px #c1bee4;
}

.dashboard .select-template-main-scroll{
    height: auto;
    overflow-y: scroll;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 40px);
}

.dashboard .select-template-table-keys{
    font-size: 0.8em;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 15px 20px;
    border-bottom: 1px solid #DFDFE3;
    color: #8B88A7;
    height: 40px;
}

.dashboard .file-icon{
    color: #8075FF;
    width: 25px;
    height: auto;
    flex-shrink: 0;
    margin-right: 10px;
}

.dashboard .empty{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 0.9em;
    min-height: 200px;
}

.dashboard .empty-icon{
    height: 4.5em;
    margin-bottom: 5px;
    color: #DFDFE3;
}

#dashboard-history{
    display: flex;
    /* margin-bottom: 20px; */
    /* border-radius: 10px; */
    /* box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3); */
    /* background-color: white; */
    width: 100%;
    /* height: 120px; */
    text-decoration: none;
    color: rgb(90, 77, 105);
    border-bottom: 1px solid #DFDFE3;
}

.histories-main > #dashboard-history:last-child {
    border: none;
}

#dashboard-history:hover{
    background-color: #F6F5FF;
}
  
#dashboard-history-updated{
    font-size: 0.75em;
    color: #8B88A7;
    width: 80px;
    text-align: right;
    flex-shrink: 0;
}

.dashboard-event{
    padding: 0.6em;
    border-radius: 10px;
    text-decoration: none;
    color: rgb(90, 77, 105);
    display: flex;
    align-items: center;
    width: 100%;
}

.dashboard-message{
    padding: 0.6em;
    border-radius: 10px;
    text-decoration: none;
    color: rgb(90, 77, 105);
    display: flex;
    width: 100%;    
}

.dashboard-event-company{
    font-size: 0.8em;
    color: #8B88A7;
    /* margin: 7px 0; */
}

.dashboard-event-date{
    font-size: 0.8em;
    color: #8B88A7;
    margin-top: 0.6em;
}

.dashboard-event-title{
    font-size: 0.9em;
    margin-bottom: 0.1em;
}

.event-left-icon{
    height: 23px;
}

.event-left-icon-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 10px;
    color: rgb(237, 147, 254);
    background-color: rgb(248, 213, 255);
}

.dashboard-event-left{
    /* width: 50px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    margin: 0 15px 0 5px;
}

.dashboard-event .interview{
    color: rgb(255, 149, 110);
    background-color: rgb(255, 223, 212);
}

.dashboard-message .public{
    background-color: #E7E5FE;
    color: #8075FF;
}

.dashboard-message .private{
    background-color: #E7E5FE;
    color: #8075FF;
}

.dashboard .events{
    width: 100%;
    /* height: 200px;
    overflow: scroll; */
}

.dashboard .event-right{
    width: calc(100% - 59px);
    overflow: hidden;
}

.dashboard-events-title{
    margin: 10px 0;
}


.dashboard .empty-message{
    width: 100%;
    height: 180px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 1em;
}

.dashboard .dashboard-membership-icon{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: radial-gradient(circle at bottom, #FBD0FF, #BAB4FF);
    font-size: 40px;
    font-weight: 800;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    color: #e0b9ff;
    margin-top: 10px;
    /* text-shadow: 3px 3px 0 white; */
    text-decoration: none;
}

.dashboard-membership-member{
    /* display: flex;
    justify-content: center; */
    margin-top: 12px;
}

.dashboard .premium{
    background: radial-gradient(circle at bottom right, #A9DBFF, #FEBCFF);
    color: #d1b9ff;
}

#dashboard-history-title{
    font-size: 0.9em;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

#dashboard-history-text-top-left{
    width: calc(100% - 80px);
}

.dashboard #history-company{
    width: 100%;    
}

.dashboard #history-company-name{
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;     
}

.dashboard #history-text{
    width: calc( 100% - 65px );
    justify-content: space-between;
}

.dashboard .stats-row{
    display: flex;
    flex-shrink: 0;
    flex: 1 1;
}

.dashboard .user-info-mobile{
    display: none;
    width: 100%;
    padding: 0 5px 5px 5px;
    flex-direction: column;
}

.dashboard .dashboard-messages-mobile{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.3);
    padding: 0 20px 20px 20px;
}

.dashboard-events-title-mobile{
    margin: 20px 0 10px 0;
    height: 22px;
    font-size: 0.9em;
    font-weight: 600;
}

#dashboard-history-score{
    font-size: 0.9em;
    border-radius: 16px;
    height: 25px;
    width: 50px;
    background-color: #E7E5FE;
    color: #8075FF;
    font-weight: 600;
    border: 0 none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dashboard-inner{
    width: 100%;
}


@media (max-width: 1200px){
    .dashboard .histories{
        flex: 5 1;
    }
    .dashboard .templates{
        flex: 5 1;
    }

    .stat-box .stat-desc{
        font-size: 0.8em;
    }

    .dashboard .main{
        padding: 10px;
    }

    .dashboard .middle{
        height: calc( 100% - 282px );
        /* height: 100%; */
    }

    /* .dashboard .middle{
        height: auto;
    } */
}

@media (max-width: 1100px){
    .dashboard .middle{
        height: auto;
        flex-direction: column;
    }

    .dashboard .histories{
        flex: auto;
        height: auto;
        margin-bottom: 20px;
    }

    .dashboard .histories-main{
        height: auto;
    }

    .dashboard .templates{
        flex: auto;
        height: auto;
        margin-bottom: 20px;
    }

    .dashboard .templates-main{
        height: auto;
    }

    .dashboard .select-template-main-scroll{
        height: auto;
    }

    .dashboard .main{
        padding-bottom: 0;
    }

    .dashboard .main-inner{
        height: auto;
    }

    .dashboard .stats{
        flex-direction: column;
    }

    .stat-box{
        margin: 10px;
    }

    .dashboard .stats{
        margin-bottom: 10px;
    }

}

@media (max-width: 700px){
    .dashboard .right-bar{
        display: none;
    }

    .dashboard .main{
        padding: 5px;
        width: 100%;
    }

    .dashboard .title{
        display: none;
    }

    .dashboard .user-info-mobile{
        display: flex;
    }

    .dashboard .stat-box{
        margin: 5px;
    }

    .dashboard .stats{
        margin-bottom: 5px;
    }

    .dashboard .histories{
        margin: 0 5px 10px 5px;
    }

    .dashboard .templates{
        margin: 0 5px 5px 5px;
    }
}

@media (max-width: 500px){
    .stat-box{
        padding: 15px;
        height: 180px;
    }
}

@media (max-width: 300px){
    .dashboard .stats-row{
        flex-direction: column;
    }
}




/* .dashboard .template-title{
    margin-bottom: 5px;
} */