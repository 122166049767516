.transactions-inner-wrapper{
    width: 400px;
    padding: 30px;
    border-radius: 10px;
    background-color: rgb(246, 245, 248);
}

.transactions-inner-wrapper .title{
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
}

.transactions-inner-wrapper .main{
    color: #8B88A7;
    font-weight: 600;
    height: max(200px, 65vh);
    position: relative;
    margin-bottom: 20px;
}

.transactions-inner-wrapper .main-scroll{
    overflow: scroll;
    position: relative;
    height: 100%;
    padding: 10px 0 30px 0;
}

.transactions-inner-wrapper .no-scroll{
    overflow: hidden;
}

/* hide scroll bar on chrome safari opera */
.transactions-inner-wrapper .main-scroll::-webkit-scrollbar {
    width: 0px;
}


.transactions-inner-wrapper .mask{
    background: linear-gradient(180deg,  rgba(246, 245, 248, 1), rgba(246, 245, 248, 0.5) 30%, rgba(246, 245, 248, 0));
    height: 30px;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: -1px;
}

.transactions-inner-wrapper .bottom-mask{
    background: linear-gradient(0deg,  rgba(246, 245, 248, 1), rgba(246, 245, 248, 0.5) 30%, rgba(246, 245, 248, 0));
    height: 30px;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: -1px;
}


.transactions-inner-wrapper .transaction{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
    margin: 10px 0;
    font-weight: 400;
    flex-direction: column;
}

.transactions-inner-wrapper .transaction-title{
    font-size: 1em;
    margin-bottom: 4px;
}

.transactions-inner-wrapper .desc{
    font-size: 13px;
}

.transactions-inner-wrapper .short-desc{
    font-size: 0.8em;
}

.transactions-inner-wrapper .right{
    width: 4em;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 1em;
    align-items: right;
}

.transactions-inner-wrapper .left{
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

/* .transactions-inner-wrapper .points-earn{
    font-size: 22px;
    font-weight: 600;
} */

.transactions-inner-wrapper .time{
    font-size: 0.7em;
    display: flex;
    justify-content: flex-end;
}

.transactions-inner-wrapper .status{
    font-size: 0.8em;
    display: flex;
    justify-content: flex-end;
}

.transactions-inner-wrapper .empty{
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.transactions-inner-wrapper .transaction-main{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.transactions-inner-wrapper .transaction-type{
    font-size: 0.8em;
    margin-bottom: 0.5em;
    color: #C5BEFF;
}

.transactions-inner-wrapper .transaction-type-skeleton{
    font-size: 0.8em;
    margin-bottom: 0.5em;
    width: 20%;
}

.transactions-inner-wrapper .transaction-desc-icon{
    height: 0.8em;
    width: auto;
    margin: 0 0.2em;
}

.transactions-inner-wrapper .points-wrapper-earn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1em;
    font-weight: 600;
    color: #C5BEFF;
    margin-bottom: 0.2em;
}

.transactions-inner-wrapper .points-wrapper-loss{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.2em;
}

@media (max-width: 400px){
    .transactions-inner-wrapper{
        width: 100%;
        height: 100%;
        border-radius: 0;        
    }
}
