.templates-page-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.template-page-top-bar{
    width: 100%;
    height: 140px;
    background-color: white;
    flex-shrink: 0;
    z-index: 5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.templates-page-wrapper .top-bar-top{
    height: 85px;
    /* -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    /* background-color: #8075FF; */
    border-bottom: 1px solid #DFDFE3;
}

.templates-page-templates-scroll{
    overflow-y: scroll;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    height: calc(100% - 140px);
    padding: 10px 15%;
    flex-direction: column;
}

/* .templates-page-templates-scroll-inner{
    width: 70%;
    padding: 10px 0 20px 0;
    display: flex;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
} */

.templates-page-wrapper .top-bar-bottom{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 55px;
}


.templates-page-wrapper .select-template-table-keys{
    font-size: 0.8em;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 10px 0;
    border-bottom: none;
    width:70%;
}

.templates-page-wrapper .template-header-title{
    width: calc(100% - 180px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
}

.templates-page-wrapper .template-title{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
}

.templates-page-wrapper .template-update-time{
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
}

.templates-page-wrapper .file{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    flex-shrink: 0;
    text-decoration: none;
    color: rgb(90, 77, 105);
    border-bottom: 1px solid #DFDFE3;
}

.templates-page-wrapper .skeleton-file{
    display: flex;
    align-items: center;
    font-size: 0.8em;
    height: 50px;
    width: 100%;
    flex-shrink: 0;
}

.templates-page-wrapper .file:hover{
    background-color: #F6F5FF;
}

.templates-page-file-icon{
    color: #8075FF;
    height: 50%;
    margin-left: 20px;
    flex-shrink: 0;
}

.templates-page-more-icon-wrapper{
    width: 35px;
    margin-right: 20px;    
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0;
    cursor: pointer;
}

.templates-page-more-icon-wrapper:hover{
    background-color: #DFDFE3;
}

.templates-page-wrapper .no-more{
    font-size: 0.8em;
    color: #8c8c8c;
    padding: 20px 0;
    text-align: center;
    height: 35px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

}

.templates-page-wrapper .empty{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d3d3d6;
    font-size: 1em;
    /* flex-shrink: 0; */
}

.templates-page-wrapper .empty-icon{
    height: 5em;
    margin-bottom: 5px;
    color: #DFDFE3;
}

.templates-page-wrapper .y-no-scroll{
    overflow-y: hidden;
}

.templates-page-wrapper .templates-scroll-bottom{
    height: 80px;
    width: 100%;
    flex-shrink: 0;
}

.template-dropdown{
    position: relative;
}

.template-dropdown-menu{
    position: absolute;
    right: 0;
    top: calc(100% + 0.5em);
    z-index: 1;
    background-color: white;
    border-radius: 5px;
    padding: 0.3em 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none;
    /* no interaction when not clicked */
}

.selected-dropdown{
    opacity: 1;
    pointer-events: auto;
}

.template-dropdown-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.7em 1em;  
    font-size: 1em;  
    cursor: pointer;
}

.template-dropdown-item:hover{
    background-color: #F6F5FF;
}

.top-bar-new-template{
    border-radius: 16px;
    height: 35px;
    width: auto;
    /* background-color: #8075FF; */
    background-color: white;
    /* color: #ffffff; */
    color:#8075FF;
    /* border: 0 none; */
    border: 1px solid #8075FF;
    padding: 10px 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    /* font-weight: 600; */
    cursor: pointer;
    flex-shrink: 0;
    text-decoration: none;
    transition: 0.5s;
    width: 145px;
}
  
.top-bar-new-template:hover{
    opacity: 70%;
}

.template-right-wrapper{
    width: 180px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.top-bar-new-template-inner-wrapper{
    display: flex;
    align-items: center;
}

@media (max-width: 800px){
  
    .templates-page-wrapper .top-bar-top{
      padding: 0;
      height: 50px;
    }
  
    .templates-page-wrapper .top-bar-bottom{
      height: 40px;
    }
  
    .template-page-top-bar{
      height: 90px;
    }
  
    .templates-page-templates-scroll{
      height: calc(100% - 90px);
      padding: 10px 0;
    }

    .templates-page-file-icon{
        margin-left: 10px;
    }

    .templates-page-wrapper .select-template-table-keys{
        width: 100%;
    }

    .templates-page-wrapper .template-update-time{
        width: 75px;
    }

    .templates-page-wrapper .template-header-title{
        width: calc(100% - 120px);
        padding: 0 10px;
    }

    .top-bar-new-template{
        margin-right: 0;
        border: #8075FF 1px solid;
        background-color: transparent;
        color: #8075FF;
        padding: 10px;
        border-radius: 5px;
        margin-right: 10px;
        width: 125px;
    }

    .template-right-wrapper{
        width: 110px;
        margin-right: 10px;
    }

    .templates-page-wrapper .template-title{
        padding: 0 10px;
    }
}