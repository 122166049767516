.confirmation-background{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.confirmation-main{
    width: 450px;
    height: 550px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    flex-shrink: 0;
    padding: 40px;
}

.confirmation-title{
    font-size: 18px;
    font-weight: 600;
    color: rgb(90, 77, 105);
    margin-top: 25px;
    text-align: center;
}

.confirmation-text{
    font-size: 14px;
    margin: 15px 0 40px 0;
    color: #767676;
    text-align: center;
}

.confirmation-login{
    width: 180px;
    height: 35px;
    background-color: #8075FF;
    color: white;
    border-radius: 18px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    transition: .5s;
}

.confirmation-login:hover{
    background-color: #C5BEFF;
}

.confirmation-img{
    height: 150px;
}

.confirmation-loader{
    border: 7px solid rgba(181, 176, 240, 0.4);
    border-left: 7px solid  rgba(181, 176, 240, 0.8);
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

@media (max-width: 900px ){

    .confirmation-main{
        width: max(50%,300px);
    }
}

@media (max-width: 400px ){

    .confirmation-main{
        width: max(100%,300px);
        height: max(100%,550px);
        border-radius: 0;
    }
}

@media (max-height: 550px ){
    .confirmation-background{
        align-items: flex-start;
    }    
}