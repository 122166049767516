.login-background{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.login-main{
    width: min(max(65vw, 600px),950px);
    height: 550px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
}

.login-right{
    width: 50%;
    height: 100%;
    background: linear-gradient(to bottom right, #B5B0F0 , #F1C1EC);
    position: relative;
}

.login-left{
    width: 50%;
    height: 100%;
    padding: 40px;
}

.login-title{
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
}

.login-logo-icon{
    height: 75px;
    width: 60px;
    margin-right: 20px;
}

.login-right-content{
    bottom: 40px;
    left: 40px;
    position: absolute;
    display: flex;
    align-items: center;
}

.login-right-text{
    color: white;
    font-weight: 700;
    font-size: 20px;
    padding-right: 20px;
}

.login-circle1{
    border-radius: 50%;
    width: 180px;
    height: 180px;
    position: absolute;
    left: 150px;
    top: 70px;
    background: linear-gradient(to bottom, rgba(192, 179, 240, 0.5) , #FFBEF9);
}

.login-circle2{
    border-radius: 50%;
    width: 550px;
    height: 550px;
    position: absolute;
    left: 115px;
    top: 330px;
    background: linear-gradient(to top left, rgba(213, 179, 240, 0.2), rgba(213, 179, 240, 0.2), rgba(213, 179, 240, 0.2)  , rgba(190, 224, 255, 0.8));
}

.login-circle3{
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    left: 260px;
    top: -50px;
    background: linear-gradient(to left, rgba(213, 179, 240, 0.2), rgba(213, 179, 240, 0.2), rgba(190, 224, 255, 0.5));
}

.login-ring{
    position: absolute;
    left: 80px;
    top: 140px;    
}

.login-ring-2{
    position: absolute;
    left: 340px;
    top: 260px;       
}

.login-plus{
    position: absolute;
    left: 385px;
    top: 50px;       
}

.login{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20% 15% 0 15%;
}

.forgot{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30% 15% 0 15%;
}

.login-txt-field{
    position: relative;
    border-bottom: 2px solid #A4A4A8;
    margin: 20px 0 5px 0;
    width: 100%;
    transition: .5s;
    background-color: white;
    z-index: 1;
}

.login-txt-field-expand{
    /* margin-bottom: 25px; */
}

.login-txt-field input{
    width: 100%;
    padding: 0 5px;
    height: 35px;
    font-size: 15px;
    border: none;
    background: none;
    outline: none;
    border-radius: 0;
}
.login-txt-field label{
    position: absolute;
    top: 50%;
    left: 5px;
    color: #A4A4A8;
    transform: translateY(-50%);
    font-size: 14px;
    pointer-events: none;
    transition: .5s;
}
.login-txt-field span::before{
    content: '';
    position: absolute;
    top: 35px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #8075FF;
    transition: .5s;
}
.login-txt-field input:focus ~ label,
.login-txt-field input:not([value='']) ~ label{
    top: -8px;
    color: #8075FF;
}
.login-txt-field input:focus ~ span::before,
.login-txt-field input:not([value='']) ~ span::before{
    width: 100%;
}

.forgot-password{
    font-size: 13px;
    color: #A4A4A8;
    width: 100%;
    text-align: left;
    margin: 0 0 15px 0;
    z-index: 1;
}

.new-to-site{
    font-size: 13px;
    color: #A4A4A8;
    width: 100%;
    text-align: left;
    margin-top: 10px;    
}

.login-to-register{
    color: #8075FF;
    text-decoration: none;
}

.login-to-register:hover{
    color: #C5BEFF;
    text-decoration: none;
}

.login-error{
    font-size: 13px;
    color: #e58787;
    width: 100%;
    text-align: left;
    display: flex;    
    align-items: center;
    /* position: absolute; */
    /* top: 62px; */
    /* white-space: nowrap; */
    opacity: 0;
    transition: .5s;
    max-height: 0px;
    /* margin-top: 5px; */
}

.login-error-active {
    opacity: 1;
    max-height: 45px;
}

.login-error-icon{
    height: 14px;
    margin: 2px 5px 0 0;
    flex-shrink: 0;
}

.login-success-icon{
    height: 16px;
    margin: 0 3px 0 0;
    flex-shrink: 0;
}

.login-field-wrapper{
    width: 100%;
    position: relative;
}

.register-spacing{
    height: 10px;
}

.register{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 13% 15% 0 15%;
}

.register-verify{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15% 15% 0 15%;
}

.register-title{
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    width: 100%;
}

.register-txt-field-expand{
    /* margin-bottom: 20px; */
}

.register-inline-button{
    text-decoration: underline;
    cursor: pointer;
}

.register-sent-wrapper{
    padding: 60px 0 0 0;
    display: flex;
    justify-content: center;
}

.register-sent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 220px;
}

.register-sent-title{
    font-size: 18px;
    font-weight: 600;
    color: rgb(90, 77, 105);
    margin-top: 25px;
    text-align: center;
}

.register-sent-text{
    font-size: 14px;
    margin: 15px 0 30px 0;
    color: #767676;
    text-align: center;
    padding: 0 10px;
}

.resend-message-success{
    font-size: 13px;
    text-align: left;
    display: flex;    
    align-items: center;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin-top: 10px;
    position: absolute;
    color: #8075FF;
    width: 100%;
}

.resend-message-failed{
    font-size: 13px;
    text-align: left;
    display: flex;    
    align-items: center;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin-top: 10px;
    position: absolute;
    color: #e58787;
}

.resend-message-active {
    opacity: 1;
}

.register-sent-button-container{
    position: relative;
    width: 100%;
}

.register-sent-img{
    height: 150px;
}

.login-inline-button{
    cursor: pointer;
}

.please-verify-email{
    z-index: 5;
}


.resend-button {
    padding: 1rem 2rem;
    font-size: 14px;
    color: white;
    font-weight: 600;
    border-radius: 18px;
    background-color: #8B88A7;
    height: 35px;
    margin-top: 5px;
}

.resend-button-loading{
    background-color: #bebcd1;  
}

.resend-button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.reset-login-button{
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 40px;
    width: 220px;
    cursor: pointer; 
    transition: opacity .4s;
    background-color: #aca4ff;
    color: white;
    flex-shrink: 0;
    text-decoration: none;
    border-radius: 20px;
}

.reset-login-button:hover{
    opacity: 70%;
}

.register-resend-status{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@media (max-width: 900px ){

    .login-main{
        width: max(60%,300px);
    }

    .login-right{
        display: none;
    }

    .register{
        padding: 15% 5% 0 5%;
    }

    .login{
        padding: 20% 5% 0 5%;
    }

    .forgot{
        padding: 30% 5% 0 5%;
    }

    .login-left{
        width: 100%;
        padding: 40px;
    }
}

@media (max-width: 400px ){

    .login-main{
        width: max(100%,300px);
        height: max(100%,550px);
        border-radius: 0;
    }

    .login-left{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .register{
        padding: 15% 0 0 0;
    }

    .login{
        padding: 20% 0 0 0;
    }

    .forgot{
        padding: 30% 0 0 0;
    }
}

@media (max-height: 550px ){
    .login-background{
        align-items: flex-start;
    }    
}