.job-post-page{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    --toastify-toast-min-height: 40px;
    --toastify-color-error: #ec6f61;
    position: absolute;
    overflow: hidden;
}

.toast-custom-style button{
    width: auto;
}

.job-post-page-top{
    height: 135px;
    background-color: white;
    width: 100%;
    position: absolute;
}

.job-post-page-main{
    display: flex;
    width: 100%;
    height: calc(100% - 135px);
    margin-top: 135px;
    position: relative;
}

.job-post-page-main-inner{
    display: flex;
    padding: 0 15%;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.job-post-page-main-main{
    display: flex;
    width: 100%;
    height: 100%;
}

.job-post-page-left-wrapper{
    overflow: scroll;
    height: 100%;
}

.job-post-page-left{
    width: 500px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
    margin: 1em;
}

.job-post-page-fixed-section{
    height: 3em;
    padding: 1em;
    display: flex;
    align-items: center;
    background-color: #f8f8ff;
    font-weight: 600;
}

.job-post-page-fixed-question-wrapper{
    width: calc(100% - 2em);
    margin: 0 1em;
    border-bottom: 1px solid #DFDFE3;
}

.fixed-question-wrapper-last{
    border-bottom: none;
}

.job-post-page-custom-question-wrapper{
    width: calc(100% - 3em);
    margin: 0 1em;
}

.job-post-page-fixed-question{
    width: 100%;
    display: flex;
    height: 3em;
    align-items: center;
    /* cursor: pointer; */
    /* flex: 0 0 auto; */
}

.job-post-page-custom-question{
    width: 100%;
    display: flex;
    height: 3em;
    align-items: center;
    cursor: pointer;
    /* flex: 0 0 auto; */
}

.job-post-page-fixed-question-arrow{
    width: 1em;
    height: 1em;
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.job-post-page-question-edit-icon{
    width: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-expanded{
    width: 1em;
    transform: rotate(180deg);
}

.job-post-page-fixed-question-title{
    width: 100%;
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.job-post-page-custom-question-title{
    width: calc(100% - 1.25em);
    font-size: 0.8em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 0;
}

.job-post-page-fixed-question-question{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 8em);
    /* background-color: #ec6f61; */
}

.job-post-page-custom-section-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 8em);
    font-size: 1.25em;    
}

.job-post-page-fixed-question-required{
    border-radius: 1em;
    /* height: 1.8em; */
    width: auto;
    height: 2.2em;
    background-color: #E7E5FE;
    color: #8075FF;
    font-size: 0.8em;
    font-weight: 600;
    border: 0 none;
    padding: 0.5em 1.2em 0.5em 1.2em;
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;    
}

.job-post-page-fixed-question-select{
    display: flex;
    align-items: center;
    position: relative;
}

.job-post-page-fixed-question-state{
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;

    border-radius: 1.5em;
    /* height: 1.8em; */
    width: 7.5em;
    height: 2.2em;
    background-color: #E7E5FE;
    color: #8075FF;
    font-size: 0.8em;
    font-weight: 600;
    border: 0 none;
    padding: 0.5em 1.8em 0.5em 1em;
    margin: 1em;
    display: inline-block;
    text-align: center;
    cursor: pointer;   
    
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' height='15px' viewBox='0 0 24 24' strokeWidth='1.5' stroke='%238075FF' className='w-6 h-6'><path strokeLinecap='round' strokeLinejoin='round' d='M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9' /></svg>");
    background-repeat: no-repeat;
    background-position-x: 5.5em;
    background-position-y: calc(1.1em - 7.5px);
}

.job-post-page-fixed-question-state:focus{
    /* border: solid 1.5px #8075FF; */
    /* border: solid 1px #8075FF; */
    box-shadow: 0 0 0 1px #8075FF;
    outline: none;
}

.job-post-page-fixed-question-state-icon{
    width: 1em;
    height: 1em;
    position: absolute;
    right: 1.3em;
    color: #8075FF;
}

.job-post-page-fixed-question-expansion{
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
}

.job-post-page-fixed-question-expansion-inner{
    padding-bottom: 2em;
    width: calc(100% - 2em);
}

.job-post-page-fixed-question-wrapper .fixed-question-expanded{
    height: auto;
    max-height: 1000px;
}

.job-post-page-custom-question-wrapper .fixed-question-expanded{
    height: auto;
    max-height: 1000px;
}

.job-post-page-right{
    width: calc(100% - 600px);
}

.dnd-area {
    list-style: none;
    padding-left: 0;
}

.dnd-area li {
    display: flex;
    align-items: center;
}

.dnd-area .drag-inner{
    display: flex;
    align-items: top;
    width: calc(100% - 2em);
    border-radius: .2em;
    padding-left: .5em;
    margin: .5em 1em;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    background-color: white;
}

.dnd-area .section{
    background-color: #f8f8ff;    
}

.dnd-area p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}

.dnd-area .drag-icon{
    width: 1em;
    padding-top: 1em;
    flex-shrink: 0;
}

.job-post-page-custom-question-input-title{
    font-size: 0.8em;
    padding: 0.7em 0;
}

.job-post-page-custom-question-input{
    width: 100%;
    display: block;
    /* margin-bottom: 40px; */
    padding: 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;
    line-height: 1.5rem;
    resize: none;
}

.job-post-page-custom-question-input:focus{
    border: solid 1px #8075FF;
    outline: none;
}

.job-post-page-custom-question-option{
    display: flex;
    align-items: center;
}

.job-post-page-custom-question-option-input{
    width: calc(100% - 2.5em);
    display: block;
    margin: 0.5em 0;
    padding: 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;
    line-height: 1.5rem;

}

.job-post-page-custom-question-option .first-option{
    margin-top: 0;
}

.job-post-page-custom-question-option-input:focus{
    border: solid 1px #8075FF;
    outline: none;
}

.job-post-page-custom-question-option-delete{
    color: #ec6f61;
    width: 1.8em;
    padding: 0 0.3em;
    margin: 0 0.1em;
    cursor: pointer;
}

.job-post-page-custom-question-section{
    margin-bottom: 0.8em;
}

.custom-question-section-add-option{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #8075FF;
    padding: 0.3em 0;
}

.custom-question-section-add-option-text{
    font-size: 0.8em;
}

.custom-question-section-add-option-icon{
    width: 1em;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 0.3em;
}

/* .job-post-page-custom-question-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #ec6f61;
    color: #ec6f61;
    width: calc(100% - 2em);
    font-size: 0.8em;
    border-radius: 5px;
    padding: 0.5em 0;
    cursor: pointer;
} */

.job-post-page-custom-question-delete{
    width: 100%;
    padding: 0.7em 0;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: #F8B9B9 solid 1px;
    color: #F8B9B9;
    font-weight: 600;
    transition: 0.5s;
    cursor: pointer;
}

.job-post-page-custom-question-delete:hover{
    color: white;
    background-color: #F8B9B9;
}

.job-post-page-custom-question-delete-disabled{
    color: #A4A4A8;
    border: #A4A4A8 solid 1px;
    cursor: not-allowed;
}

.job-post-page-custom-question-input-desc{
    color: #8c8c8c;
    font-size: 0.7em;
    margin-bottom: 1em;
}

.job-post-page-dropdown{
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;   

    width: 100%;
    display: block;
    /* margin-bottom: 40px; */
    padding: 7px 38px 7px 10px;
    border: 1px solid;
    border-color: rgb(213, 213, 213);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    font-family: Helvetica;
    font-size: 0.8em;
    line-height: 1.5rem;
    cursor: pointer;
    
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' height='18px' viewBox='0 0 24 24' stroke='%23D5D5D5' className='w-6 h-6'><path strokeLinecap='round' strokeLinejoin='round' stroke-width='2' d='m19.5 8.25-7.5 7.5-7.5-7.5' /></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: calc(0.75rem - 2px);
}


.job-post-page-dropdown:focus{
    /* border: solid 1.5px #8075FF; */
    border: solid 1px #8075FF;
    /* box-shadow: 0 0 0 1px #8075FF; */
    outline: none;
}
  

@media (max-width: 400px){
    .job-post-page-fixed-question-state{        
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' height='12.5px' viewBox='0 0 24 24' strokeWidth='1.5' stroke='%238075FF' className='w-6 h-6'><path strokeLinecap='round' strokeLinejoin='round' d='M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9' /></svg>");
        background-position-y: calc(1.1em - 6.25px);
    }
}