.edit-template-main-left{
  height: 100%;
  display: flex;
  flex-direction: column;
  /* take up remaining space */
  flex: 1; 
  font-size: 12px;
  width: 100%;
  position: absolute;
}

.edit-template-main{
  width: 100%;
  height: calc(100% - 85px);
  display: flex;
  position: absolute;
}

.edit-template-main-left-content{
  height: calc(100% - 50px);
  display: flex;
  position: relative;
  top: 50px;
  width: 100%;
  /* overflow-y: scroll; */
}

.edit-template-editor-wrapper{
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-top: 1px solid #DFDFE3;
  display: flex;
  justify-content: center;
  /* min-width: calc(8.5in + 40px); */
}

.edit-template-main .ql-container{
  height: auto !important;
}

.edit-template-main .ql-editor{
  height: auto !important;
}

.edit-template-editor-container{
  /* a full page width is 2550 pixels */
  width: 8.5in;
  flex-shrink: 0;
  /* overflow-x: scroll; */
  padding: 20px 20px 20px 20px;
  /* flex-shrink: 0; */
  height: max-content;
}

.edit-template-editor-container .ql-editor{
  padding: 0.6in;
  min-height: 11in;

}

@keyframes upload-load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.upload-status-loader {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid;
  animation: upload-load 1s infinite linear;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.upload-status {
  font-size: 14px;
  color: #8075FF;
  font-weight: 600;
  background-color: transparent;
  height: 30px;
  cursor: auto;    
  width: 100px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.upload-status-loading{
  font-size: 14px;
  color: #A4A4A8;
  font-weight: 600;
  height: 30px;
  cursor: auto;    
  width: 100px;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.upload-status > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.upload-status-loading > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.upload-updating-text{
  margin-left: 10px;
}

.upload-updated-icon{
  height: 20px;
  margin: 5px;
}

.edit-template-container{
  overflow: hidden;
  /* width not relative to parent becaused position: fixed */
  position: absolute; 
  /* width: calc(100% - 80px); */
  width: 100%;
  height: 100%;
}

.edit-template-header-company-container{
  display: flex;
  align-items: center;
  margin-top: 3px;
  width: 100%;
}

.edit-template-header-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 302px);
}

.edit-template-header-title{
  font-size: 15px;
  color: #333;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-template-header-left-main{
  width: calc(100% - 60px);
}

@media (max-width: 600px){
  .upload-status{
    display: none;
  }

  .upload-status-loading{
    display: none;
  }

  .edit-template-main{
    height: calc(100% - 50px);
  }

  .edit-template-header-left{
    width: calc(100% - 110px);
    margin-left: 10px;
  }

  .edit-template-header-left-main{
    width: 100%;
  }
}

@media (max-width: 900px){
  .edit-template-editor-wrapper{
    display: block;
  }
}

@media print{
  .edit-template-editor-wrapper{
    border: none;
  }

  .edit-template-editor-container{
    /* width: 8.5in; */
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .edit-template-editor-wrapper{
    border: none;
    margin: 0;
  }

  .edit-template-main-left-content{
    top: 0;
    border: none;
    height: auto;
  }

  .edit-template-editor-container .ql-editor{
    padding: 0.2in;
    min-height: 0;
    /* 0.6 - 0.4 = 0.2, 0.4 is chrome default margin */
    /* border: #767676 3px solid; */
  }

  .edit-template-header{
    display: none;
  }
}